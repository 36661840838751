import { message } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import img from "../../images/doc/doctor 3.jpg";
import { useContactMutation } from "../../redux/api/contactApi";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import "./index.css";

const Contact = () => {
  const [contact, { isLoading, isError, error, isSuccess }] =
    useContactMutation();
  const { register, handleSubmit, reset } = useForm({});
  const onSubmit = (data) => {
    contact(data);
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Successfully Message Send !");
    }
    if (isError && error) {
      message.error(error?.data?.message);
    }
  }, [isSuccess, isError, error]);
  return (
    <>
      <Header />
      <SubHeader
        title="Contact us"
        // subtitle="Lorem ipsum dolor sit amet consectetur adipisicing."s
      />
      <section id="contact" className="contact mt-5 mb-5">
        <div className="container" style={{ marginTop: 80, marginBottom: 120 }}>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="contact-card mb-3">
                <div className="row">
                  <div className="col-2">
                    <FaMapMarkerAlt
                      className="contact-icon"
                      style={{ fontSize: "2.5rem" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6 style={{ color: "white" }}>Address</h6>
                    <span style={{ fontWeight: "100" }}>
                      SPINAL PAIN AND REHAB MEDICAL PC BINOD SHAH M.D. 970 N.
                      BROADWAY , SUITE # 310 YONKERS, NY 10701
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="contact-card mb-3">
                <div className="row">
                  <div className="col-2">
                    <FaEnvelope
                      className="contact-icon"
                      style={{ fontSize: "2.5rem" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6 style={{ color: "white" }}>Email</h6>
                    <span style={{ fontWeight: "100" }}>
                      medrec970@gmail.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="contact-card">
                <div className="row">
                  <div className="col-2">
                    <FaPhoneAlt
                      className="contact-icon"
                      style={{ fontSize: "2.5rem" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6 style={{ color: "white" }}>Phone</h6>
                    <span style={{ fontWeight: "100" }}>(914) 207-1161</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-5">
              <div className="rounded">
                <img
                  src={img}
                  alt="image"
                  className="rounded"
                  style={{
                    height: "42.5rem",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>

            <div className="col-lg-7">
              <div
                className="mb-5 p-2 rounded"
                style={{ background: "#418ead30" }}
              >
                <form
                  className="row form-row p-3"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="text-center">
                    <h6>Get In Touch</h6>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group mb-2 card-label">
                      {/* <label>First Name</label> */}
                      <input
                        required
                        {...register("firstName")}
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mt-4">
                    <div className="form-group mb-2 card-label">
                      {/* <label>Last Name</label> */}
                      <input
                        required
                        {...register("lastName")}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <div className="form-group mb-2 card-label">
                      {/* <label>Email</label> */}
                      <input
                        required
                        {...register("email")}
                        type="email"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <div className="form-group mb-2 card-label">
                      {/* <label>Subject</label> */}
                      <input
                        required
                        {...register("subject")}
                        className="form-control"
                        placeholder="Enter your subject"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <div className="form-group">
                      <label className="form-label">Message</label>
                      <textarea
                        required
                        {...register("text")}
                        className="form-control mb-3"
                        cols="30"
                        rows="10"
                        placeholder="Enter your message"
                      />
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="more-btn"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            {/* eslint-disable-next-line */}
            <iframe
              style={{ border: 0, width: "100%", height: "350px" }}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
