import React from "react";
import "./index.css";
import SearchForm from "./SearchForm";
import { Link } from "react-router-dom";
import { FaCalendar} from "react-icons/fa";

const HeroSection = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container">
      <div className="d-flex  align-items-center md:mb-3 lg:mb-4">
            <Link to={'/quick-appointment'}><button className="btn btn-primary appointment"> <FaCalendar className="mr-1 inline" />Quick Appointment</button></Link>
          </div>
        <div>
          {/* <small>TOTAL HEALTH CARE SOLUTION</small> */}
          <h1>
            Your Most Trusted <br />
            Health Partner
          </h1>
          {/* <small>A repudiandae ipsam labore ipsa voluptatum quidem quae laudantium quisquam aperiam maiores sunt fugit, deserunt rem suscipit placeat.</small> */}
        </div>
        {/* <div className="d-flex justify-content-start gap-2">
                    <Link to={'/doctors'} className="btn-get-started scrollto">Get Started</Link>
                    <Link to={'/track-appointment'} className="btn-get-started scrollto">Track Appointment</Link>
                </div> */}

          <SearchForm />

       
      </div>
    </section>
  );
};
export default HeroSection;
