const setHospitalColor = (hospitalArray) => {
  // Define an array of colors to cycle through
  const colorPalette = [
    "#2C3E50", // Dark Blue
    "#8E44AD", // Purple
    "#16A085", // Teal
    "#E74C3C", // Red
    "#34495E", // Dark Gray
    "#27AE60", // Green
    "#F39C12", // Yellow
    "#D35400", // Orange
    "#2980B9", // Blue
    "#7F8C8D", // Gray
  ];

  return hospitalArray?.map((item, index) => {
    const color = colorPalette[index % colorPalette.length];

    return {
      ...item,
      color,
    };
  });
};

export default setHospitalColor;
