import { Button, message, Spin } from "antd";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useGetTermAndConditionQuery,
  useUpsertTermAndConditionMutation,
} from "../../../redux/api/settingApi";
import RichEditor from "../../../utils/RichEditor";
import AdminLayout from "../AdminLayout/AdminLayout";

const Terms = () => {
  const { data, isLoading } = useGetTermAndConditionQuery();

  const [updateTerms, { isLoading: isUpdating }] =
    useUpsertTermAndConditionMutation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      termsAndConditions: "",
      privacyPolicy: "",
    },
  });

  const onSubmit = async (formData) => {
    const updatedData = await updateTerms({ data: { ...data, ...formData } });
    reset({
      termsAndConditions: updatedData.data.termsAndConditions,
      privacyPolicy: updatedData.data.privacyPolicy,
    });
    message.success("Terms updated successfully");
  };

  useEffect(() => {
    if (!data) return;
    const { termsAndConditions, privacyPolicy } = data;
    reset({ termsAndConditions, privacyPolicy });
  }, [data]);

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  return (
    <AdminLayout>
      <h1>Terms</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-4">
        <div className="flex flex-col gap-2 ">
          <div>
            <label>Terms and Condtions</label>
            <Controller
              name="termsAndConditions"
              control={control}
              render={({ field }) => (
                <RichEditor
                  onValueChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
          <div>
            <label>Privacy Policy</label>
            <Controller
              name="privacyPolicy"
              control={control}
              render={({ field }) => (
                <RichEditor
                  onValueChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <Button htmlType="submit" className="mt-2" size="large">
          {isUpdating ? "Saving..." : "Save"}
        </Button>
      </form>
    </AdminLayout>
  );
};

export default Terms;
