import { Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetDoctorsDDLQuery } from "../../../redux/api/doctorApi";
import { useGetHospitalDDLQuery } from "../../../redux/api/hospital";
import { useDebounced } from "../../../utils/hooks/useDebounced";
import "./SearchBar.css";

const SearchBar = () => {
  const navigate = useNavigate();
  const { data: doctors } = useGetDoctorsDDLQuery();
  const { data: hospitals } = useGetHospitalDDLQuery();
  const [searchQuery, setSearchQuery] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const debouncedSearchQuery = useDebounced({ searchQuery, delay: 300 });
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleSelectChange = (value, option) => {
    if (!value || !value) {
      setDoctorName("");
      setHospitalName("");
      return;
    }
    if (option.type === "doctor") {
      setDoctorName(option.label.props.children[2]);
    } else if (option.type === "hospital") {
      setHospitalName(value);
    }
  };

  const doctorOptions = useMemo(
    () =>
      doctors?.map((doctor) => ({
        value: doctor.id,
        label: (
          <span className="flex items-center">
            <img
              src={doctor.img}
              className="rounded-circle"
              style={{ width: "20px", height: "20px" }}
              alt={doctor.fullName}
            />
            &nbsp;{doctor.fullName}
          </span>
        ),
        type: "doctor",
      })) || [],
    [doctors]
  );

  const hospitalOptions = useMemo(
    () =>
      hospitals?.map((hospital) => ({
        value: hospital.id,
        label: (
          <span className="flex items-center">
            <img
              src={hospital.img}
              className="rounded-circle"
              style={{ width: "20px", height: "20px" }}
              alt={hospital.name}
            />
            &nbsp;{hospital.name}
          </span>
        ),
        type: "hospital",
      })) || [],
    [hospitals]
  );

  const handleNavigation = () => {
    const queryParams = { Dname: doctorName, Hname: hospitalName };
    navigate(`/doctors?${new URLSearchParams(queryParams).toString()}`);
  };

  useEffect(() => {
    if (doctors && hospitals) {
      const filteredDoctors = doctorOptions.filter((option) =>
        option.label.props.children[2]
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
      const filteredHospitals = hospitalOptions.filter((option) =>
        option.label.props.children[2]
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
      setFilteredOptions([...filteredDoctors, ...filteredHospitals]);
    }
  }, [debouncedSearchQuery, doctorOptions, hospitalOptions]);

  return (
    <div className="flex lg:justify-end sm:mb-3">
      <div className="d-flex relative border p-1 rounded">
        <div className="absolute top-1/2 -translate-y-1/2 left-2 z-30">
          <FaSearch className=" text-brand" />
        </div>

        <Select
          showSearch
          allowClear
          className="search md:min-w-[20rem] ps-3"
          placeholder="Search Doctor, clinic"
          onSearch={setSearchQuery}
          onChange={handleSelectChange}
          filterOption={false}
          suffixIcon={null}
          options={filteredOptions}
        />
        <button
          className="text-sm px-2 bg-brand text-white rounded disabled:bg-gray-300"
          onClick={handleNavigation}
          disabled={!doctorName && !hospitalName}
        >
          Book
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
