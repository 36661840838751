import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaAngleLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import BlogIcon from "../../../images/blogIcon.png";
import {
  useCreateBlogMutation,
  useGetBlogCategoryDDLQuery,
} from "../../../redux/api/blogApi";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import ImageUpload from "../../UI/form/ImageUpload";
import AdminLayout from "../AdminLayout/AdminLayout";

const AddBlog = () => {
  const { data: userData } = useAuthCheck();
  const [blogCategoryList, setBlogCategoryList] = useState([]);

  const { data: blogCategory, isLoading: blogCategoryLoading } =
    useGetBlogCategoryDDLQuery();
  const [createBlog, { isLoading, isError, error, isSuccess }] =
    useCreateBlogMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      categoryId: "",
      active: true,
    },
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    data["userId"] = userData?.id;
    const blogData = JSON.stringify(data);

    const formData = new FormData();
    formData.append("data", blogData);

    if (selectedImage) {
      formData.append("file", file);
    }
    await createBlog(formData);
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Blog Added !");
      navigate("/admin/blogs");
    }
  }, [isLoading, isError, error, isSuccess]);

  useEffect(() => {
    if (blogCategory && !blogCategoryLoading) {
      setBlogCategoryList(blogCategory);
    }
  }, [blogCategory, blogCategoryLoading]);
  return (
    <AdminLayout>
      <div className="flex items-center gap-3 mb-3">
        <Link
          to="/admin/blogs"
          className="getbackButton hover:text-white hover:bg-brand/90"
        >
          {" "}
          <FaAngleLeft className="inline" /> Go Back
        </Link>
        <h3 className="m-0">Add Blog </h3>
      </div>
      <div className="card mb-5 p-2 shadow-sm">
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Title</label>
              <input
                placeholder="Title"
                {...register("title", { required: true })}
                className="form-control"
              />
              {errors.title?.type === "required" && (
                <span className="text-danger">Title is required</span>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Blog Category</label>
              <select
                {...register("categoryId", { required: true })}
                placeholder="Select Blog Category"
                className="form-control"
              >
                <option value="">Select blog category</option>
                {blogCategoryList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errors.categoryId?.type === "required" && (
                <span className="text-danger">Category is required</span>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Description</label>
              <textarea
                placeholder="Description"
                {...register("description", { required: true })}
                className="form-control"
                rows={5}
              />
              {errors.description?.type === "required" && (
                <span className="text-danger">Description is required</span>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 flex gap-2 items-center ">
              <label>Active</label>
              <input type="checkbox" {...register("active")} />
            </div>
          </div>

          <div className="col-md-12">
            <div className="d-flex gap-2 align-items-center">
              <div className="my-3">
                <img
                  className=""
                  style={{ maxWidth: "150px" }}
                  src={selectedImage ? selectedImage : BlogIcon}
                  alt=""
                />
              </div>
              <div className="mt-3">
                <ImageUpload
                  setFile={setFile}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className="text-center my-3">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {isLoading ? "Saving ..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AddBlog;
