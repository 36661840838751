import { Button, Checkbox, DatePicker, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import dImage from "../../../images/doctor.png";
import ImageUpload from "../../UI/form/ImageUpload";

import { FaAngleLeft } from "react-icons/fa";
import "./UpsertPatients.css";

import {
  useCreatePatientsMutation,
  useGetPatientQuery,
  useUpdatePatientMutation,
} from "../../../redux/api/patientApi";
import GoogleMapComponent from "../../Shared/GoogleMaps/googlemaps";

const UpsertPatients = ({ setShowForm, id }) => {
  const [updatePatient, { isLoading, isSuccess, isError, error }] =
    useUpdatePatientMutation();
  const [
    createPatient,
    {
      isLoading: createisLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
    },
  ] = useCreatePatientsMutation();
  const {
    data,
    isLoading: isLoadingDoctor,
    isError: isErrorDoctor,
  } = useGetPatientQuery(id, {
    skip: !id,
  });
  const { register, handleSubmit, setValue, reset, watch } = useForm({});
  const [selectValue, setSelectValue] = useState({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [location, setLocation] = useState(null);
  useEffect(() => {
    if (id && data) {
      const {
        firstName,
        lastName,
        dateOfBirth,
        bloodGroup,
        mobile,
        city,
        state,
        zipCode,
        gender,
        country,
        email,
        address,
        img,
        receiveSMS,
        latitude,
        longitude,
      } = data;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("bloodGroup", bloodGroup);
      setValue("mobile", mobile);
      setValue("city", city);
      setValue("state", state);
      setValue("zipCode", zipCode);
      setValue("gender", gender);
      setValue("country", country);
      setValue("email", email);
      setValue("address", address);
      setValue("receiveSMS", receiveSMS);
      setDate(dateOfBirth ? moment(dateOfBirth) : null);
      setSelectedImage(img || dImage);
      if (latitude != null || longitude != null) {
        setLocation({
          lat: latitude,
          lng: longitude,
        });
      }
    } else {
      reset();
    }
  }, [id, data, setValue, reset]);

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
  };
  const onChange = (date, dateString) => {
    setDate(moment(dateString));
  };

  const onSubmit = async (data) => {
    const obj = data;
    obj.price && obj.price.toString();
    const newObj = {
      ...obj,
      ...selectValue,
      latitude: location?.lat,
      longitude: location?.lng,
    };
    date && (newObj["dateOfBirth"] = date);
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );
    const formData = new FormData();

    selectedImage && formData.append("file", file);

    const changeData = JSON.stringify(changedValue);

    formData.append("data", changeData);
    if (id) {
      await updatePatient({ data: formData, id });
    } else {
      await createPatient({ data: formData });
    }

    if (setShowForm) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Changed Saved !");
    }
  }, [isLoading, isError, error, isSuccess]);
  useEffect(() => {
    if (!createisLoading && createIsError) {
      message.error(error?.data?.message);
    }
    if (createIsSuccess) {
      message.success("Successfully  Saved !");
    }
  }, [createisLoading, createIsError, createIsSuccess]);

  return (
    <div style={{ marginBottom: "10rem" }}>
      <div
        className="w-100 mb-3 rounded mb-5 p-2"
        style={{ background: "#f8f9fa" }}
      >
        <div className="top">
          <h5 className="text-title mb-2 mt-3">
            {id ? "Update" : "Create"} Patients
          </h5>
          {setShowForm && (
            <button
              className="getbackButton"
              onClick={() => {
                setShowForm(false);
              }}
            >
              {" "}
              <FaAngleLeft className="inline" /> Go Back
            </button>
          )}
        </div>
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 mb-5">
            <div className="form-group">
              <div className="change-avatar d-flex gap-2 align-items-center">
                <Link to={"/"} className="my-3 patient-img">
                  <img
                    src={selectedImage ? selectedImage : data?.img || dImage}
                    alt=""
                  />
                </Link>
                <div className="mt-3">
                  <ImageUpload
                    setSelectedImage={setSelectedImage}
                    setFile={setFile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                First Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.firstName}
                {...register("firstName")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.lastName}
                {...register("lastName")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Email</label>
              <input
                defaultValue={data?.email}
                {...register("email")}
                className="form-control"
              />
            </div>
          </div>
          {!id && (
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>Password</label>
                <input
                  type="password"
                  {...register("password")}
                  className="form-control"
                />
              </div>
            </div>
          )}

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Phone Number</label>
              <input
                defaultValue={data?.mobile}
                {...register("mobile")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Gender</label>
              <select
                className="form-control select"
                onChange={handleChange}
                name="gender"
                value={watch("gender") || data?.gender || ""}
              >
                <option value={""}>Select</option>
                <option value={"male"}>male</option>
                <option value={"female"}>female</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Date of Birth</label>
              <DatePicker
                onChange={onChange}
                format={"YYYY-MM-DD"}
                style={{ width: "100%", padding: "6px" }}
                value={date} // Set the value of the DatePicker
              />
            </div>
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>Blood Group</label>
                <select
                  className="form-control select"
                  onChange={handleChange}
                  name="bloodGroup"
                  value={watch("bloodGroup")} // Set the value of the select
                >
                  <option value={""}>Select</option>
                  <option value={"O-"}>O-</option>
                  <option value={"O+"}>O+</option>
                  <option value={"A-"}>A-</option>
                  <option value={"A+"}>A+</option>
                  <option value={"B-"}>B-</option>
                  <option value={"B+"}>B+</option>
                  <option value={"AB-"}>AB-</option>
                  <option value={"AB+"}>AB+</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">SMS Consent</h6>
              <div className="row form-row">
                <div className="col-md-12">
                  <Checkbox
                    checked={watch("receiveSMS")}
                    {...register("receiveSMS", {
                      onChange: (e) => setValue("receiveSMS", e.target.checked),
                    })}
                  >
                    <span className="text-sm text-muted">
                      We can send you text message and email reminders, as well
                      as updates about your upcoming appointments. By checking
                      the box, you consent to receiving these notifications. You
                      may update your preferences at any time.
                    </span>
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Contact Details</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Address Line</label>
                    <input
                      defaultValue={data?.address}
                      {...register("address")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>City</label>
                    <input
                      defaultValue={data?.city}
                      {...register("city")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>State / Province</label>
                    <input
                      defaultValue={data?.state}
                      {...register("state")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Country</label>
                    <input
                      defaultValue={data?.country}
                      {...register("country")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Postal Code</label>
                    <input
                      defaultValue={data?.zipCode}
                      {...register("zipCode")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-2 card-label">
                    <label>
                      Google Location <span className="text-danger">*</span>
                    </label>
                    <GoogleMapComponent
                      location={location}
                      setLocation={setLocation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center my-3 ">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {id
                ? isLoading
                  ? "Updating ..."
                  : "Update Changes"
                : createisLoading
                ? "Saving ..."
                : "Save Changes"}
            </Button>
            <Button
              htmlType="button"
              className="ms-2"
              size="large"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpsertPatients;
