import { Button, DatePicker, Modal, Tag, Tooltip, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPrint, FaSms } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  useGetSingleAppointmentQuery,
  useUpdateAppointmentByDoctorMutation,
} from "../../../redux/api/appointmentApi";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { clickToCopyClipBoard } from "../../../utils/copyClipBoard";
import { SettingContext } from "../../context/setting";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import PrescriptionView from "../Prescription/PrescriptionView";
import "./index.css";

const NotificationCard = ({ children, sentAt, sentTries, isEmail }) => {
  const deriveStatus = () => {
    if (sentAt) return "Sent";
    if (sentTries === 3) return "Failed";
    return "Not Sent";
  };
  const deriveColor = () => {
    if (sentAt) return "bg-green-100";
    if (sentTries === 3) return "bg-red-100";
    return "bg-yellow-100";
  };

  return (
    <div
      className={`flex justify-between [&_p]:text-sm [&_p]:font-bold [&_p>span]:font-normal border shadow-sm p-1 rounded ${deriveColor()}`}
    >
      <div>
        {children}
        <p className="m-1">
          Status: <span>{deriveStatus()}</span>
        </p>
      </div>
      <div className="flex items-center gap-1">
        {isEmail ? (
          <>
            <MdOutlineEmail />
            Email
          </>
        ) : (
          <>
            <FaSms />
            SMS
          </>
        )}
      </div>
    </div>
  );
};

const ViewAppointment = () => {
  const setting = useContext(SettingContext);
  const [ShowPrice, setShowPrice] = useState(setting[0].isDisplayPrice);
  const ref = useRef();
  const { role } = useAuthCheck();
  const { id } = useParams();
  const { data, isLoading, isError, refetch } =
    useGetSingleAppointmentQuery(id);
  const [
    updateAppointment,
    { isSuccess: AisSuccess, isLoading: AisLoading, isError: AisError },
  ] = useUpdateAppointmentByDoctorMutation();
  const [ChangeShedule, SetChangeSchedule] = useState(false);
  const [newSchedule, setNewSchedule] = useState("");
  const [reScheduledByPatient, setReScheduledByPatient] = useState(null);

  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Are you sure you want to Cancel Appointment"
  );

  const [prescriptionId, setPrescriptionId] = useState(null);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setModalText("Cancelling Appointment...");
    setConfirmLoading(true);
    handleReject();
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const handleChange = (value) => {
    setNewSchedule(value.format("YYYY-MM-DD hh:mm A"));
    console.log(value.format("YYYY-MM-DD hh:mm A"));
  };
  const SaveNewSchedule = () => {
    setIsPending((prev) => true);
    SetChangeSchedule(false);

    if (newSchedule) {
      const data = {
        id: id,
        isPending: true,
        newScheduleDateAndTime: newSchedule,
        isPatientApproved: isAccepted,
        isCancelled: isCancelled,
        reScheduledByPatient: role === "patient",
      };
      updateAppointment({ id, data })
        .then(() => {
          message.success("Rescheduled request sent !!");
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setIsPending((prev) => false);
        });
    }
  };

  const handleAccept = () => {
    setIsAccepted(true);
    setIsCancelled(false);
    setIsPending(false);
    const data = {
      id: id,
      isPending: false,
      newScheduleDateAndTime: newSchedule,
      isPatientApproved: true,
      isCancelled: false,
      reScheduledByPatient: null,
    };
    updateAppointment({ id, data })
      .then(() => {
        message.success("Successfully Accepted !!");
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setIsPending((prev) => false);
      });
  };
  const handleReject = () => {
    setIsCancelled(true);
    setIsAccepted(false);
    setIsPending(false);
    const data = {
      id: id,
      isPending: false,
      newScheduleDateAndTime: newSchedule,
      isPatientApproved: false,
      isCancelled: true,
      cancelledByDoctor: role === "doctor",
      reScheduledByPatient: null,
    };
    updateAppointment({ id, data })
      .then(() => {
        message.success("Successfully Rejected !!");
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setIsPending((prev) => false);
      });
  };

  const showNewScheduleReponseHtml = () => {
    return (
      (role === "doctor" && reScheduledByPatient) ||
      (role === "patient" && !reScheduledByPatient)
    );
  };

  useEffect(() => {
    refetch();
    setReScheduledByPatient(data?.reScheduledByPatient);
    setIsCancelled(data?.isCancelled ?? false);
    setIsAccepted(data?.isPatientApproved ?? false);
    setIsPending(data?.isPending ?? false);
    setNewSchedule(data?.newScheduleDateAndTime ?? " ");
    setConfirmLoading(false);
    setOpen(false);
    setPrescriptionId(data?.prescription[0]?.id);
  }, [AisSuccess, data]);
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (isLoading && !isError) content = <h2>Loading...</h2>;
  if (!isLoading && !isError && data?.id)
    content = (
      <>
        {/* <page size="A4" className="container mx-auto border border-primary-subtle p-3 pb-3">
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='form-text text-black mb-0'>Creation Date : <Tag bordered={false} color="volcano">{moment(data?.createdAt).format('LL')}</Tag></p>
                        <Tooltip title="Copy Tracking Id">
                            <Button>
                                <h6>Tracking<Tag color="#87d068" className='ms-2 text-uppercase' onClick={() => clickToCopyClipBoard(data?.trackingId)}>{data?.trackingId}</Tag></h6>
                            </Button>
                        </Tooltip>
                    </div>

                    <div style={{ fontWeight: 500 }}>
                        {data?.patientType &&
                        <p className='mb-1'>Patient Type : <Tag bordered={false} color="processing">{data?.patientType}</Tag></p>}
                        <p className='mb-1'>Current Status:  <Tag bordered={false} color="orange">{data?.status}</Tag></p>
                        <p className='mb-1'>Payment : <Tag bordered={false} color="success">{data?.paymentStatus}</Tag></p>
                        <p className='mb-1'>Prescription Status : <Tag bordered={false} color="green">{data?.prescriptionStatus}</Tag></p>
                    </div>
                </div>

                <div>
                    <h4 className='text-center my-3 fw-bold'>
                        APPOINTMENT INFOMATION
                    </h4>
                    <div className='border border-light-subtle rounded p-3'>
                        <p className='mb-1'>Place of Meeting : <Tag bordered={false} color="#f50">ONLINE</Tag></p>
                        <p className='mb-1'>Meeting Link : <a href="https://meet.google.com/udx-kieq-sng" target='_blank' rel='noreferrer'>https://meet.google.com/udx-kieq-sng</a></p>
                        <p className='mb-1'>Meeting Date : <Tag bordered={false} color="orange">{moment(data?.scheduleDate).format('LL')}</Tag></p>
                        <p className='mb-1'>Meeting Time : <Tag bordered={false} color="orange">{data?.scheduleTime}</Tag></p>
                    </div>
                </div>

                <div>
                    <h4 className='text-center my-3 fw-bold text-secondary'>DOCTOR INFOMATION</h4>
                    {
                        data?.doctor &&
                        <div className='border border-light-subtle rounded p-3 d-flex gap-3'>
                            <div>
                                <img src={data?.doctor?.img} alt="" style={{ border: '2px solid #ffbc21', width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover', objectPosition: 'top' }} />
                            </div>
                            <div>
                                <h4 className="mb-1">{data?.doctor?.firstName && data?.doctor?.lastName ? `${data.doctor.firstName} ${data.doctor.lastName}` : (data?.doctor?.firstName || data?.doctor?.lastName)}</h4>
                                <p className="mb-1">{data?.doctor?.specialization}</p>
                                <p className="mb-1 form-text">{data?.doctor?.designation}</p>
                                <p className="mb-1 form-text">{data?.doctor?.college}</p>
                            </div>
                        </div>
                    }
                </div>

                <div>
                    <h4 className='text-center my-3 fw-bold text-secondary'>PATIENT INFOMATION</h4>
                    <div className='border border-light-subtle rounded p-3 d-flex gap-3'>
                        <div>
                            <img src={data?.patient?.img} alt="" style={{ border: '2px solid #ffbc21', width: '80px', height: '80px', borderRadius: '50%', objectFit: 'cover', objectPosition: 'top' }} />
                        </div>
                        <div>

                            <h4 className="mb-1">{data?.patient?.firstName + ' ' + data?.patient?.lastName}</h4>
                            <p className="mb-1 form-text">Age : {moment().diff(moment(data?.patient?.dateOfBirth), 'years')}</p>
                            <p className="mb-1 form-text">Blood Group : {data?.patient?.bloodGroup}</p>
                            <p className="mb-1 form-text">{data?.patient?.city + ' , ' + data?.patient?.state + ' , ' + data?.patient?.country}</p>

                            <div className='mt-2'>
                                <p>Reason for Visit - <span className='text-warning'>{data?.reasonForVisit}</span></p>
                                <p className='text-warning'>{data?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </page> */}

        <page
          size="A4"
          className="container mx-auto border border-primary-subtle p-3 pb-3"
        >
          <div
            className="d-flex justify-content-between"
            style={{ fontWeight: 500 }}
          >
            <div>
              <p
                className="form-text text-black mb-0"
                style={{ fontSize: "1rem" }}
              >
                Creation Date :{" "}
                <Tag bordered={false} color="volcano">
                  {moment(data?.createdAt).format("LL")}
                </Tag>
              </p>
              <Tooltip title="Copy Tracking Id">
                <p style={{ fontSize: "1rem" }}>
                  Tracking
                  <Tag
                    color="#87d068"
                    style={{ background: "var(--primary)" }}
                    className="ms-2 mt-3 text-uppercase"
                    onClick={() => clickToCopyClipBoard(data?.trackingId)}
                  >
                    {data?.trackingId}
                  </Tag>
                </p>
              </Tooltip>
            </div>

            <div style={{ fontWeight: 500 }}>
              {data?.patientType && (
                <p className="mb-1">
                  Patient Type :{" "}
                  <Tag bordered={false} color="processing">
                    {data?.patientType}
                  </Tag>
                </p>
              )}
              <p className="mb-1">
                Current Status:{" "}
                <Tag bordered={false} color="orange">
                  {data?.status}
                </Tag>
              </p>
              {ShowPrice && (
                <>
                  <p className="mb-1">
                    Payment :{" "}
                    <Tag bordered={false} color="success">
                      {data?.paymentStatus}
                    </Tag>
                  </p>
                  <p className="mb-1">
                    Prescription Status :{" "}
                    <Tag bordered={false} color="green">
                      {data?.prescriptionStatus}
                    </Tag>
                  </p>
                </>
              )}
              {data?.payment && (
                <p className="mb-1">
                  Insurance Plan :{" "}
                  <Tag bordered={false} color="green">
                    {data?.payment[0]?.insurancePlan?.name}
                  </Tag>
                </p>
              )}
            </div>
          </div>

          <div className="mt-5">
            <h6 className="my-3" style={{ color: "var(--primary)" }}>
              APPOINTMENT INFORMATION
            </h6>

            <div className="border border-light-subtle rounded p-3">
              {/* <p className="mb-1">
                Place of Meeting :{" "}
                <Tag bordered={false} color="#f50">
                  ONLINE
                </Tag>
              </p> */}
              <p className="mb-1">
                Hospital :{" "}
                <Tag bordered={false} color={"var(--primary"}>
                  {data?.hospital?.name}
                </Tag>
              </p>

              <p className="mb-1">
                Hospital Address : {data?.hospital?.address}
              </p>
              {/* <p className="mb-1">
                Meeting Link :{" "}
                <a
                  href="https://meet.google.com/udx-kieq-sng"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://meet.google.com/udx-kieq-sng
                </a>
              </p> */}
              <p className="mb-1">
                Meeting Date :{" "}
                <Tag bordered={false} color="orange">
                  {moment(data?.scheduleDate).format("LL")}
                </Tag>
              </p>
              <p className="mb-1">
                Meeting Time :{" "}
                <Tag bordered={false} color="orange">
                  {data?.scheduleTime}
                </Tag>
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 col-sm-12">
              <h6 className="my-3" style={{ color: "var(--primary)" }}>
                DOCTOR INFORMATION
              </h6>
              <div className="row">
                <div className="col-4">
                  {" "}
                  <div>
                    <img
                      src={data?.doctor?.img}
                      alt=""
                      style={{
                        width: "100%",
                        height: "11rem",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="col-8 d-flex flex-column justify-content-center">
                  <div>
                    <p className="mb-1">
                      {data?.doctor?.firstName && data?.doctor?.lastName
                        ? `${data.doctor.firstName} ${data.doctor.lastName}`
                        : data?.doctor?.firstName || data?.doctor?.lastName}
                    </p>
                    <p className="mb-1">{data?.doctor?.specialization}</p>
                    <p className="mb-1 form-text">
                      {data?.doctor?.designation}
                    </p>
                    <p className="mb-1 form-text">{data?.doctor?.college}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-1 dotted-line"
              style={{ height: "15rem" }}
            ></div>
            <div className="col-md-5 col-sm-12">
              <h6 className="my-3" style={{ color: "var(--primary)" }}>
                PATIENT INFORMATION
              </h6>
              <div className="row">
                <div className="col-8 d-flex flex-column justify-content-center">
                  <div>
                    <p className="mb-1">
                      {data?.patient?.firstName + " " + data?.patient?.lastName}
                    </p>
                    {data?.patient?.dateOfBirth && (
                      <p className="mb-1 form-text">
                        Age:{" "}
                        {moment().diff(
                          moment(data?.patient?.dateOfBirth),
                          "years"
                        )}
                      </p>
                    )}
                    {data?.patient?.bloodGroup && (
                      <p className="mb-1 form-text">
                        Blood Group : {data?.patient?.bloodGroup}
                      </p>
                    )}

                    {data?.patient?.city &&
                      data?.patient?.state &&
                      data?.patient?.country && (
                        <p className="mb-1 form-text">
                          {`${data.patient.city}, ${data.patient.state}, ${data.patient.country}`}
                        </p>
                      )}

                    <div className="mt-2">
                      <p>
                        Reason for Visit - <span>{data?.reasonForVisit}</span>
                      </p>
                      <p className="text-warning">{data?.description}</p>
                    </div>
                  </div>
                </div>

                <div className="col-4 d-flex justify-content-center">
                  {" "}
                  <div>
                    <img
                      src={data?.patient?.img}
                      alt=""
                      style={{
                        width: "100%",
                        height: "11rem",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                {data?.existingConditions ||
                  data?.currentMedications ||
                  data?.allergies ||
                  (data?.recentTest && (
                    <>
                      <div className="mt-2">
                        <h4>Medical Details</h4>
                        {data?.existingConditions && (
                          <p>
                            Existing Conditions : {data?.existingConditions}
                          </p>
                        )}
                        {data?.currentMedications && (
                          <p>
                            Current Medications : {data?.currentMedications}
                          </p>
                        )}
                        {data?.allergies && (
                          <p>Allergies : {data?.allergies}</p>
                        )}
                        {data?.recentTest && (
                          <p>Recent Test : {data?.recentTest}</p>
                        )}
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </page>
      </>
    );
  return (
    <>
      <Header />
      <div style={{ margin: "6rem 7rem" }}>
        <div className="d-flex justify-content-between mb-4">
          <div ss="d-flex flex-column">
            {!isCancelled &&
              // reScheduledByPatient === null &&
              data?.status !== "completed" &&
              data?.status !== "cancel" && (
                <div className="d-flex flex-column">
                  <div>
                    <Button
                      style={{
                        background: "var(--primary)",
                        color: "var(--white)",
                      }}
                      onClick={() => {
                        SetChangeSchedule(!ChangeShedule);
                      }}
                    >
                      {ChangeShedule ? "Cancel" : "Change Schedule"}
                    </Button>

                    {isPending &&
                      role == "doctor" &&
                      !reScheduledByPatient &&
                      !ChangeShedule && (
                        <span>
                          {" "}
                          Waiting for Patient Approval. Rescheduled To :{" "}
                          {newSchedule}
                        </span>
                      )}
                    {isPending &&
                      role == "patient" &&
                      reScheduledByPatient &&
                      !ChangeShedule && (
                        <span>
                          {" "}
                          Waiting for Doctor Approval. Rescheduled To :{" "}
                          {newSchedule}
                        </span>
                      )}
                  </div>
                  <div className="mt-2 ">
                    {ChangeShedule && (
                      <>
                        <DatePicker
                          className="me-2"
                          showTime={{ use12Hours: true, format: "HH:mm a" }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={handleChange}
                        />
                        <Button
                          className="bg-success text-white"
                          onClick={SaveNewSchedule}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            <div>
              {isPending &&
                reScheduledByPatient != null &&
                showNewScheduleReponseHtml() && (
                  <>
                    <div
                      className="alert alert-primary d-flex gap-2 align-items-center"
                      role="alert"
                    >
                      {role == "patient" ? "Doctor " : "Patient "} have
                      ReSchedule To {newSchedule} , Would you ??
                      <Button
                        type="primary"
                        style={{ background: "#7cb342" }}
                        onClick={handleAccept}
                      >
                        Accept
                      </Button>
                      <Button type="primary" danger onClick={showModal}>
                        Reject
                      </Button>
                    </div>
                  </>
                )}
            </div>
          </div>

          <ReactToPrint
            bodyClass="print-agreement"
            content={() => ref.current}
            trigger={() => (
              <Button
                style={{ background: "var(--primary)", color: "var(--white)" }}
                icon={<FaPrint />}
              >
                {" "}
                Print
              </Button>
            )}
          />
        </div>
        <div ref={ref}>
          {content}
          {prescriptionId && (
            <div className="mt-5">
              <h2>View Prescription</h2>
              <PrescriptionView prescriptionId={prescriptionId} />
            </div>
          )}
        </div>
        {role === "doctor" && data?.notifications?.length > 0 && (
          <div>
            <h1 className="my-3">Notifications</h1>
            <div className="flex flex-col gap-4">
              {data.notifications.map((group) => (
                <div
                  key={group.groupKey}
                  className="flex flex-col gap-2 border p-2 shadow-sm rounded-lg"
                >
                  {group.items.map((item) => {
                    const isEmail = !!item.email;
                    const notification = isEmail ? item.email : item.sms;
                    return (
                      <NotificationCard
                        key={item.id}
                        {...notification}
                        isEmail={isEmail}
                      >
                        {isEmail ? (
                          <>
                            <p className="m-1">
                              To: <span>{notification.to}</span>
                            </p>
                            <p className="m-1">
                              Subject: <span>{notification.subject}</span>
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="m-1 flex items-center">
                              To:{" "}
                              <span>
                                {notification.to}
                                <span className="text-xs italic ms-2">
                                  ({item.toDoctor ? "doctor" : "patient"})
                                </span>
                              </span>
                            </p>
                            <p className="m-1">
                              Message: <span>{notification.message}</span>
                            </p>
                          </>
                        )}
                      </NotificationCard>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <Footer />
      <Modal
        title="Cancel Appointment"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

export default ViewAppointment;
