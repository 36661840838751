import { Button, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import { useInvitePatientMutation } from "../../../redux/api/doctorApi";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import DashboardLayout from "../DashboardLayout/DashboardLayout";

const QRCodeBlock = ({ registrationLink }) => {
  const qrCodeRef = useRef(null);

  const handlePrint = () => {
    const qrCodeContent = qrCodeRef.current.innerHTML; // Get the QR code HTML

    // Create an invisible iframe
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";
    document.body.appendChild(iframe);

    // Write the QR code content to the iframe
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Print QR Code</title>
          <style>
            body {
              text-align: center;
              padding: 50px;
            }
            img {
              max-width: 100%;
              height: auto;
            }
            h1{
              margin-bottom: 5rem;
            }
          </style>
        </head>
        <body>
          <h1>Please scan below QR code for registration</h1>
          ${qrCodeContent}
        </body>
      </html>
    `);
    doc.close();

    // Wait for the content to load, then trigger the print
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    // Clean up the iframe after printing
    iframe.onload = () => {
      document.body.removeChild(iframe);
    };
  };

  return (
    <>
      {" "}
      <div
        style={{
          height: "auto",
          margin: "2rem auto",
          width: "100%",
          maxWidth: 250,
        }}
        ref={qrCodeRef}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={registrationLink}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div className="flex justify-center">
        {registrationLink && (
          <Button
            htmlType="button"
            type="default"
            size="large"
            onClick={handlePrint}
          >
            Print QR Code
          </Button>
        )}
      </div>
    </>
  );
};

const InvitePatient = () => {
  const { data } = useAuthCheck();
  const [registrationLink, setRegistrationLink] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ email: "", phone: "" });

  const [invitePatient, { isLoading, isSuccess, isError }] =
    useInvitePatientMutation();

  const onSubmit = async (formData) => {
    try {
      const { data: responseData } = await invitePatient({
        doctorId: data.id,
        data: formData,
      });

      const { inviteSent, patientExist } = responseData;

      if (inviteSent) {
        message.success("Invite successfully sent to patient.");
      } else if (patientExist) {
        message.warning(
          "A patient with this email is already registered in the system."
        );
      }
      // clear  the form
      reset();
    } catch (error) {
      message.error("An error occurred while sending the invite.");
    }
  };

  useEffect(() => {
    if (data?.id) {
      const currentHost = window.location.origin;
      setRegistrationLink(`${currentHost}/register/${data.id}`);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <div style={{ marginBottom: "2rem" }}>
        <h4>Invite Patient</h4>
        <p>
          Please fill out the patient email to invite a patient to the
          application.
        </p>
        <div
          className="w-100  rounded mb-5 p-2"
          style={{ background: "#f8f9fa" }}
        >
          <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12">
              <div className="form-group mb-2 card-label">
                <label>Email</label>
                <input
                  {...register("email", {
                    required: true,
                  })}
                  placeholder="Enter your patient email"
                  type="email"
                />
              </div>
              {errors.email && (
                <span className="text-danger">Patient email is required</span>
              )}
            </div>
            <div className="col-md-12">
              <div className="form-group mb-2 card-label">
                <label>Phone</label>
                <input
                  {...register("phone")}
                  placeholder="Enter your patient number"
                  type="text"
                  onInput={(e) => {
                    // Allow only valid phone number characters
                    e.target.value = e.target.value.replace(
                      /[^0-9+\-\(\)\s]/g,
                      ""
                    );
                  }}
                />
              </div>
              {errors.phone && (
                <span className="text-danger">Patient phone is required</span>
              )}
            </div>

            <div className="text-center my-3 ">
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isLoading}
                disabled={isLoading}
              >
                {isLoading ? "Inviting.." : "Invite Now"}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <p>You can print the below QR code for patient registration.</p>
      {registrationLink ? (
        <QRCodeBlock registrationLink={registrationLink} />
      ) : (
        <h3 className="text-center">Generating QR code..</h3>
      )}
    </DashboardLayout>
  );
};

export default InvitePatient;
