import { Button, Pagination, Table, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { FaEdit, FaRegTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  useDeleteBlogCategoryMutation,
  useGetAllBlogCategoriesQuery,
} from "../../../redux/api/blogApi";
import { useDebounced } from "../../../utils/hooks/useDebounced";
import AdminLayout from "../AdminLayout/AdminLayout";

const BlogCategoryList = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const debouncedQuery = useDebounced({ searchQuery: search, delay: 300 });

  const { data, isLoading, isFetching } = useGetAllBlogCategoriesQuery({
    limit: size,
    page,
    searchTerm: debouncedQuery,
  });

  const [deleteBlogCategory] = useDeleteBlogCategoryMutation();

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CreatedAt",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text) => <>{moment(text).format("LLL")}</>,
    },
    {
      title: "Action",
      key: 4,
      render: function (data) {
        return (
          <div className="d-flex">
            <Link to={`/admin/blog-category/${data.id}`}>
              <Button
                type="primary"
                className="bg-primary"
                style={{ margin: "5px 5px" }}
              >
                <FaEdit />
              </Button>
            </Link>
            <Button
              onClick={() => deleteHandler(data.id)}
              type="primary"
              style={{ margin: "5px 5px" }}
              danger
            >
              <FaRegTimesCircle />
            </Button>
          </div>
        );
      },
    },
  ];

  const deleteHandler = async (id) => {
    message.loading("Deleting ...");
    try {
      const res = await deleteBlogCategory(id);
      if (res) {
        message.success("Successfully Deleted !!");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  return (
    <AdminLayout>
      <h3>Blog Categories</h3>
      <div className="flex items-center mt-4 mb-4">
        <Link
          to={`/admin/blog-category/create`}
          className="d-flex justify-content-end p-3 "
        >
          <Button
            type="primary"
            size="middle"
            className="bg-brand hover:bg-brand/80"
          >
            Add Blog Category
          </Button>
        </Link>
      </div>

      <div className="w-full ">
        <div className="overflow-x-auto">
          <Table
            columns={columns}
            dataSource={data?.categories}
            loading={isLoading || isFetching}
            pagination={false}
            className="min-w-full bg-[white]"
          />
        </div>
        <div className="flex justify-center md:justify-end py-4">
          <Pagination
            className="pagination"
            current={page}
            pageSize={size}
            total={data?.meta?.total || 0}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={handlePageChange}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default BlogCategoryList;
