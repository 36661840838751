import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";

const Setting_URL = "/setting";

export const settingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSetting: build.query({
      query: () => ({
        url: `${Setting_URL}`,
        method: "GET",
      }),
      providesTags: [tagTypes.setting],
    }),
    updateSetting: build.mutation({
      query: ({ data }) => ({
        url: `${Setting_URL}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: [tagTypes.setting],
    }),
    getTermAndCondition: build.query({
      query: () => ({
        url: `${Setting_URL}/terms`,
        method: "GET",
      }),
    }),
    upsertTermAndCondition: build.mutation({
      query: ({ data }) => ({
        url: `${Setting_URL}/upsertTerm`,
        method: "PATCH",
        data: data,
      }),
    }),
  }),
});

export const {
  useGetSettingQuery,
  useUpdateSettingMutation,
  useGetTermAndConditionQuery,
  useUpsertTermAndConditionMutation,
} = settingApi;
