import { Button, Tabs, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaBriefcaseMedical, FaCheck, FaEye, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import img from "../../../../images/avatar.jpg";
import {
  useGetDoctorAppointmentsQuery,
  useUpdateAppointmentMutation,
} from "../../../../redux/api/appointmentApi";
import useAuthCheck from "../../../../redux/hooks/useAuthCheck";
import CustomTable from "../../../UI/component/CustomTable";

const DashboardPage = () => {
  const [sortBy, setSortBy] = useState("upcoming");
  const { role } = useAuthCheck();
  const { data, refetch, isLoading } = useGetDoctorAppointmentsQuery({
    sortBy,
  });
  const [updateAppointment, { isError, isSuccess, error }] =
    useUpdateAppointmentMutation();

  const handleOnselect = (key) => {
    setSortBy(key); // Set sortBy based on the selected tab
    refetch();
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Successfully Appointment Updated");
    }
    if (isError) {
      message.error(error?.data?.message);
    }
  }, [isSuccess, isError, error]);

  const updatedApppointmentStatus = (data, type) => {
    const changeObj = {
      status: type,
    };
    if (type === "cancel") {
      changeObj.cancelledByDoctor = role === "doctor";
    }
    if (data.id) {
      updateAppointment({ id: data.id, data: changeObj });
    }
  };

  const upcomingColumns = [
    {
      title: "Patient Name",
      key: "1",
      width: 100,
      render: function (data) {
        const fullName = `${data?.patient?.firstName ?? ""} ${
          data?.patient?.lastName ?? ""
        }`;
        const patientName = fullName.trim() || "Un Patient";
        const imgdata = data?.patient?.img ? data?.patient?.img : img;
        return (
          <div className="table-avatar">
            <a className="avatar avatar-sm mr-2 d-flex gap-2">
              <img className="avatar-img rounded-circle" src={imgdata} alt="" />
              <div>
                <p className="p-0 m-0 text-nowrap">{patientName}</p>
                <p className="p-0 m-0">{data?.patient?.designation}</p>
              </div>
            </a>
          </div>
        );
      },
    },
    {
      title: "App Date",
      key: "2",
      width: 100,
      render: function (data) {
        return (
          <div>
            {moment(data?.scheduleDate).format("LL")}{" "}
            <span className="d-block text-info">{data?.scheduleTime}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "3",
      width: 100,
      render: function (data) {
        return (
          <Tag
            color={data?.status !== "cancel" ? `#87d068` : `red`}
            className="text-uppercase"
          >
            {data?.status}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "4",
      width: 100,
      render: function (data) {
        return (
          <div className="d-flex gap-2">
            {data.prescriptionStatus === "notIssued" &&
            data?.status !== "cancel" ? (
              <Link to={`/dashboard/appointment/treatment/${data?.id}`}>
                <Button
                  type="primary"
                  icon={<FaBriefcaseMedical />}
                  size="small"
                >
                  Treatment
                </Button>
              </Link>
            ) : (
              <Link
                to={
                  data?.status === "cancel"
                    ? `/dashboard/appointments/${data?.id}`
                    : `/dashboard/prescription/${data?.prescription[0]?.id}`
                }
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<FaEye />}
                  size="small"
                />
              </Link>
            )}
            {data?.status === "pending" && (
              <Button
                type="primary"
                icon={<FaCheck />}
                size="small"
                onClick={() => updatedApppointmentStatus(data, "scheduled")}
              >
                Accept
              </Button>
            )}

            {data?.status !== "Completed" && data?.status !== "cancel" && (
              <Button
                type="primary"
                icon={<FaTimes />}
                size="small"
                danger
                onClick={() => updatedApppointmentStatus(data, "cancel")}
              >
                Cancel
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const items = [
    {
      key: "upcoming",
      label: "Upcoming",
      children: (
        <CustomTable
          loading={isLoading}
          columns={upcomingColumns}
          dataSource={data?.data?.filter((item) => item?.status !== "cancel")}
          showPagination={true}
          pageSize={10}
          showSizeChanger={true}
        />
      ),
    },
    {
      key: "today",
      label: "Today",
      children: (
        <CustomTable
          loading={isLoading}
          columns={upcomingColumns}
          dataSource={data?.data}
          showPagination={true}
          pageSize={10}
          showSizeChanger={true}
        />
      ),
    },
    {
      key: "cancelled",
      label: "Cancelled",
      children: (
        <CustomTable
          loading={isLoading}
          columns={upcomingColumns}
          dataSource={data?.data}
          showPagination={true}
          pageSize={10}
          showSizeChanger={true}
        />
      ),
    },
  ];

  return (
    <Tabs defaultActiveKey="upcoming" items={items} onChange={handleOnselect} />
  );
};

export default DashboardPage;
