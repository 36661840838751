import React from "react";
import icon from "../../../images/img/tilde.png";
// import { PiTildeBold } from "react-icons/pi";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import { useGetAllSpecialistQuery } from "../../../redux/api/specialites";

import { useNavigate } from "react-router-dom";
import Heading from "../../UI/component/Heading";
import "./index.css";

const ClinicAndSpecialities = () => {
  const navigate = useNavigate();
  // const specialtiesToShow = doctorSpecialistArray.slice(0, 4);

  const { data, isLoading, isError } = useGetAllSpecialistQuery({ limit: 4 });
  const specialist = data?.specialist;

  if (!isLoading && specialist?.length === 0) return null;

  return (
    <section className="clinic">
      <div className="container">
        <Heading name="Clinic and Specialities" iconUrl={icon} />
      </div>

      {isLoading && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}

      {!isLoading && specialist?.length > 0 && (
        <div className="clinic-content mt-5">
          <div className="container">
            <div className="wrapper mb-3">
              <div className="wrapper">
                {specialist.map((item, key) => (
                  <div
                    className="circle-content-wrapper"
                    key={key}
                    onClick={() => {
                      navigate(`/specialites/${item?.id}`);
                    }}
                  >
                    <div className="circle">
                      <img
                        src={item?.icon}
                        alt="specialist"
                        style={{ width: "70%", height: "70%" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center mt-2">
              <Link
                to={"/specialites"}
                className="more-btn scrollto "
                style={{ color: "white", border: "1px solid white" }}
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ClinicAndSpecialities;
