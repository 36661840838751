import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
} from "@mui/material";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventInfo from "./EventInfo";
import { useGetDoctorAppointmentsQuery } from "../../../redux/api/appointmentApi";
import AppointmentInfo from "./AppointmentInfo";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const statusColors = {
  pending: "orange",
  accepted: "green",
  cancel: "red",
  scheduled: "limegreen", // Replaced lightgreen with limegreen
  rescheduled: "#b64fc8",
  completed: "blue",
  old: "gray", // Added color for old events
};

const AppointmentCalendar = () => {
  const [events, setEvents] = useState([]);
  const { data, isLoading, isError } = useGetDoctorAppointmentsQuery({});
  const [currentView, setCurrentView] = useState(Views.DAY);
  const [eventInfoModal, setEventInfoModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    if (data?.data) {
      const mappedEvents = data.data.map((appointment) => {
        const startTime = new Date(
          `${appointment.scheduleDate} ${appointment.scheduleTime}`
        );
        const endTime = new Date(startTime.getTime() + 15 * 60000); // Add 15 minutes
        const isOld = startTime < new Date(); // Check if the event is in the past

        return {
          id: appointment.id,
          title: `${appointment?.patient?.firstName} ${appointment?.patient?.lastName} - ${appointment?.status}`,
          start: startTime,
          end: endTime,
          description: (
            <div className="flex justify-between w-auto" key={appointment?.id}>
              <img
                src={appointment?.patient?.img}
                alt={appointment?.patient?.firstName}
                className="rounded-full h-[20px] w-[20px]"
              />
              <span>{appointment?.patient?.firstName}</span>
            </div>
          ),
          status: isOld ? "old" : appointment?.status?.toLowerCase(),
          patientImg: appointment?.patient?.img,
          patientName: `${appointment?.patient?.firstName} ${appointment?.patient?.lastName}`,
          scheduleDate: appointment?.scheduleDate,
          scheduleTime: appointment?.scheduleTime,
        };
      });

      setEvents(mappedEvents);
    }
  }, [data]);

  const handleSelectSlot = (event) => {
    setCurrentEvent(event);
    if (event == null) {
      console.log("No Appointment On this day");
    }
  };

  const handleSelectEvent = (event) => {
    setCurrentEvent(event);
    setEventInfoModal(true);
  };

  return (
    <Box mt={2} mb={2} component="main" sx={{ flexGrow: 1, py: 2 }}>
      <div className="flex flex-wrap  gap-x-4 gap-y-4 mb-4">
        {Object.entries(statusColors).map(([status, color]) => (
          <div key={status} className="flex items-center space-x-2">
            <div
              className={`w-5 h-5 rounded-full`}
              style={{ backgroundColor: color }}
            ></div>
            <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
          </div>
        ))}
      </div>

      <Container maxWidth={false}>
        <Card>
          <CardHeader title="Appointment Calendar" />
          <Divider />
          <CardContent>
            <Divider style={{ margin: 10 }} />
            <AppointmentInfo
              open={eventInfoModal}
              handleClose={() => setEventInfoModal(false)}
              currentEvent={currentEvent}
            />
            <Calendar
              localizer={localizer}
              events={events}
              step={15}
              timeslots={1}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
              views={["month", "week", "day"]}
              startAccessor="start"
              endAccessor="end"
              components={{ event: EventInfo }}
              defaultView={Views.MONTH}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: statusColors[event.status] || "#b64fc8",
                  borderColor: statusColors[event.status] || "#b64fc8",
                },
              })}
              style={{ height: 900 }}
              onView={setCurrentView}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default AppointmentCalendar;
