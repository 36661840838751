import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";

const BLOGS_URL = "/blogs";

export const blogApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBlogCategory: build.query({
      query: (id) => ({
        url: `${BLOGS_URL}/categories/${id}`,
        method: "GET",
      }),
      providesTags: ["BlogCategories"],
    }),
    getAllBlogCategories: build.query({
      query: () => ({
        url: `${BLOGS_URL}/categories`,
        method: "GET",
      }),
      transformResponse: (response) => {
        return {
          categories: response.data,
          meta: response.meta,
        };
      },
      providesTags: ["BlogCategories"],
    }),
    getBlogCategoryDDL: build.query({
      query: () => ({
        url: `${BLOGS_URL}/categories/dropdown`,
        method: "GET",
      }),
      providesTags: ["BlogCategories-ddl", "dropdown"],
    }),
    createBlogCategory: build.mutation({
      query: (data) => ({
        url: `${BLOGS_URL}/categories`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: ["BlogCategories", "BlogCategories-ddl"],
    }),
    updateBlogCategory: build.mutation({
      query: ({ data, id }) => ({
        url: `${BLOGS_URL}/categories/${id}`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: ["BlogCategories", "BlogCategories-ddl"],
    }),
    deleteBlogCategory: build.mutation({
      query: (id) => ({
        url: `${BLOGS_URL}/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BlogCategories", "BlogCategories-ddl"],
    }),
    createBlog: build.mutation({
      query: (data) => ({
        url: `${BLOGS_URL}`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [tagTypes.blogs, "DoctorBlogs"],
    }),
    getAllBlogs: build.query({
      query: (arg) => ({
        url: `${BLOGS_URL}/`,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          blogs: response.data,
          meta: response.meta,
        };
      },
      providesTags: [tagTypes.blogs],
    }),
    getDoctorBlogs: build.query({
      query: (arg) => ({
        url: `${BLOGS_URL}/doctor`,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          blogs: response.data,
          meta: response.meta,
        };
      },
      providesTags: ["DoctorBlogs"],
    }),
    getSingleBlog: build.query({
      query: (id) => ({
        url: `${BLOGS_URL}/${id}`,
        method: "GET",
      }),
      providesTags: [tagTypes.blogs],
    }),
    updateBlog: build.mutation({
      query: ({ data, id }) => ({
        url: `${BLOGS_URL}/${id}`,
        method: "PATCH",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [tagTypes.blogs, "DoctorBlogs"],
    }),
    deleteBlog: build.mutation({
      query: (id) => ({
        url: `${BLOGS_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [tagTypes.blogs, "DoctorBlogs"],
    }),
  }),
});

export const {
  useGetBlogCategoryQuery,
  useGetAllBlogCategoriesQuery,
  useGetBlogCategoryDDLQuery,
  useCreateBlogCategoryMutation,
  useUpdateBlogCategoryMutation,
  useDeleteBlogCategoryMutation,
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useGetDoctorBlogsQuery,
  useUpdateBlogMutation,
  useGetSingleBlogQuery,
} = blogApi;
