import { baseApi } from "./baseApi";

const ADMIN_URL = "/admin";

export const queuedApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getQueuedEmail: build.query({
      query: (arg) => ({
        url: `${ADMIN_URL}/queued-email`,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          queuedEmail: response.data,
          meta: response.meta,
        };
      },
    }),
    getQueuedSMS: build.query({
      query: (arg) => ({
        url: `${ADMIN_URL}/queued-sms`,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          queuedSMS: response.data,
          meta: response.meta,
        };
      },
    }),
  }),
});

export const { useGetQueuedEmailQuery, useGetQueuedSMSQuery } = queuedApi;
