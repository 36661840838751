import { AutoComplete, Pagination, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useGetQueuedSMSQuery } from "../../../../redux/api/queuedApi";
import { useDebounced } from "../../../../utils/hooks/useDebounced";
import AdminLayout from "../../AdminLayout/AdminLayout";

const QueuedSMS = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const debouncedQuery = useDebounced({ searchQuery: search, delay: 300 });

  const { data, isError, isLoading, isFetching } = useGetQueuedSMSQuery({
    limit: size,
    page,
    searchTerm: debouncedQuery,
  });

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, record) => (
        <div className="w-[30rem] text-nowrap overflow-hidden overflow-ellipsis">
          {text}
        </div>
      ),
    },
    {
      title: "CreatedAt",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text, record) => <>{moment(text).format("LLL")}</>,
    },
    {
      title: "SentTries",
      key: "sentTries",
      dataIndex: "sentTries",
    },
    {
      title: "SentAt",
      key: "sentAt",
      dataIndex: "sentAt",
      render: (text) => (
        <>{text ? moment(text).format("LLL") : "Not sent yet"}</>
      ),
    },
  ];

  const handleSearch = () => {
    setPage(1);
  };

  const onSearch = (input) => {
    setSearch(input);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
  };

  const handleReset = () => {
    setSearch(""); // Clear the search state
    setPage(1); // Reset to the first page
  };

  return (
    <AdminLayout>
      {" "}
      <div className="flex items-center mt-4 mb-4">
        <AutoComplete
          style={{ width: 400 }}
          className="h-full"
          placeholder="Search SMS"
          onSearch={onSearch}
          value={search}
          allowClear={true}
        />

        <button
          className="bg-[#2c657b] p-2 rounded ml-2 text-white h-full"
          onClick={() => handleSearch()}
        >
          <FaSearch />
        </button>

        <button
          className="bg-[#2c657b] px-2 py-1 rounded ml-2 text-white"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
      <p className="text-sm text-gray-700">
        Hint:{" "}
        <span className="italic">you can search specific like "to:+123"</span>
      </p>
      <div className="w-full ">
        <div className="overflow-x-auto">
          <Table
            columns={columns}
            dataSource={data?.queuedSMS}
            loading={isLoading || isFetching}
            pagination={false}
            className="min-w-full bg-[white]"
          />
        </div>
        <div className="flex justify-center md:justify-end py-4">
          <Pagination
            className="pagination"
            current={page}
            pageSize={size}
            total={data?.meta?.total || 0}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={handlePageChange}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default QueuedSMS;
