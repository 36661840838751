import { Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePendingAppointmentResponseMutation } from "../../redux/api/authApi";

const PendingAppointmentCommand = () => {
  const { id, status } = useParams();
  const navigate = useNavigate();

  const [response, { isLoading, isError, isSuccess, error }] =
    usePendingAppointmentResponseMutation();

  useEffect(() => {
    if (id && status) {
      response({ id, status });
    }
  }, [id, status, response]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 3000); // Redirect to login after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [isSuccess, navigate]);

  if (isLoading)
    return (
      <div className="md:h-screen flex items-center justify-center">
        <Spin size="large" />
      </div>
    );

  if (isError)
    return (
      <div className="md:h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-red-600">
            {error?.data.message ?? "Something Went Wrong!"}
          </h2>

          <p className="text-gray-600">
            {error?.data.message
              ? "Appointment has already been responded."
              : "Please try again"}
          </p>
        </div>
      </div>
    );

  if (isSuccess)
    return (
      <div className="md:h-screen flex items-center justify-center">
        <div className="text-center">
          {status === "approve" ? (
            <h2 className="text-2xl font-semibold text-green-600">
              Appointment has been scheduled successfully!
            </h2>
          ) : (
            <h2 className="text-2xl font-semibold text-red-600">
              Appointment has been cancelled successfully!
            </h2>
          )}
          <p className="text-gray-600 mt-2">
            You will be redirected to the login page shortly.
          </p>
        </div>
      </div>
    );

  return null;
};

export default PendingAppointmentCommand;
