import React from "react";
import { FaMapMarked } from "react-icons/fa";

const HospitalMapLink = ({ hospital }) => {
  return (
    <div>
      <a
        className="map-link"
        id="map-link"
        title={hospital?.name}
        href={`https://www.google.com/maps/place/?q=place_id:${hospital?.placeId}`}
        target="_blank"
        rel="noreferrer"
      >
        <FaMapMarked className="text-brand" />
      </a>
    </div>
  );
};

export default HospitalMapLink;
