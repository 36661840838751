import { Button, Drawer, Popover } from "antd";
import {
  FaAddressBook,
  FaBars,
  FaBloggerB,
  FaHome,
  FaPhoneAlt,
  FaSignInAlt,
  FaUserCircle,
  FaUserMd,
  FaWrench,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

const HeaderNav = ({ open, setOpen, isLoggedIn, data, avatar, content }) => {
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          <li>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/quick-appointment"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Quick Appointment
            </NavLink>
          </li>
          {/* <li><NavLink to={'/about'} className={({ isActive }) => isActive ? "nav-link scrollto active" : ""}>About</NavLink></li> */}
          <li>
            <NavLink
              to={"/service"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/doctors"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Doctors
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/hospital"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Hospitals
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/contact"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/blog"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              Blog
            </NavLink>
          </li>
          {!isLoggedIn && (
            <li>
              <Link to={"/login"} className="nav-link login scrollto">
                Login/Register
                <FaUserCircle className="user-icon ms-3" />
              </Link>
            </li>
          )}
        </ul>
        {isLoggedIn && (
          <div>
            <Popover content={content}>
              <div className="profileImage">
                <img
                  src={data?.img ? data?.img : avatar}
                  alt=""
                  className="profileImage shadow img-fluid"
                />
              </div>
            </Popover>
          </div>
        )}
        <FaBars className="mobile-nav-toggle" onClick={showDrawer} />
      </nav>
      <Drawer
        placement={"left"}
        width={270}
        onClose={onClose}
        open={open}
        size={"default"}
        extra={
          <Button type="primary" onClick={onClose}>
            {" "}
            Close
          </Button>
        }
      >
        <ul className="mobile-menu-nav">
          <li>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaHome className="icon inline" />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/about"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaAddressBook className="icon inline" />
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/service"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaWrench className="icon inline" />
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/doctors"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaUserMd className="icon inline" />
              Doctors
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/hospital"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaUserMd className="icon inline" />
              Hospitals
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/contact"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaPhoneAlt className="icon inline" />
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/blog"}
              className={({ isActive }) =>
                isActive ? "nav-link scrollto active" : ""
              }
            >
              <FaBloggerB className="icon inline" />
              Blog
            </NavLink>
          </li>
          {!isLoggedIn && (
            <li>
              <Link to={"/login"} className="nav-link scrollto">
                <FaSignInAlt className="icon inline" />
                Login
              </Link>
            </li>
          )}
        </ul>
      </Drawer>
    </>
  );
};

export default HeaderNav;
