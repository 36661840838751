import { Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FaSearch, FaTooth } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetDoctorsDDLQuery } from "../../../redux/api/doctorApi";
import { useGetHospitalDDLQuery } from "../../../redux/api/hospital";
import { useDebounced } from "../../../utils/hooks/useDebounced";
import "./index.css";

const SearchForm = () => {
  const { data: doctors } = useGetDoctorsDDLQuery();
  const { data: hospitals } = useGetHospitalDDLQuery();
  const [doctorName, setDoctorName] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounced({ searchQuery, delay: 300 });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const navigate = useNavigate();

  const doctorOptions = useMemo(
    () =>
      doctors?.map((doctor) => ({
        value: doctor.id,
        label: (
          <span className="flex items-center">
            <img
              src={doctor.img}
              className="rounded-circle"
              style={{ width: "20px", height: "20px" }}
              alt={doctor.fullName}
            />
            &nbsp;{doctor.fullName}
          </span>
        ),
        type: "doctor",
      })) || [],
    [doctors]
  );

  const hospitalOptions = useMemo(
    () =>
      hospitals?.map((hospital) => ({
        value: hospital.id,
        label: (
          <span className="flex items-center">
            <img
              src={hospital.img}
              className="rounded-circle"
              style={{ width: "20px", height: "20px" }}
              alt={hospital.name}
            />
            &nbsp;{hospital.name}
          </span>
        ),
        type: "hospital",
      })) || [],
    [hospitals]
  );

  useEffect(() => {
    if (doctors && hospitals) {
      const filteredDoctors = doctorOptions.filter((option) =>
        option.label.props.children[2]
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
      const filteredHospitals = hospitalOptions.filter((option) =>
        option.label.props.children[2]
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
      setFilteredOptions([...filteredDoctors, ...filteredHospitals]);
    }
  }, [debouncedSearchQuery, doctorOptions, hospitalOptions]);

  const handleNavigation = () => {
    const queryParams = { Dname: doctorName, Hname: hospitalName };
    navigate(`/doctors?${new URLSearchParams(queryParams).toString()}`);
  };

  const handleSelectChange = (value, option) => {
    if (option.type === "doctor") {
      setDoctorName(option.label.props.children[2]);
    } else if (option.type === "hospital") {
      setHospitalName(value);
    }
  };

  return (
    <div className="hero-card mt-5">
      <div className="wrapper-hero-card">
        <div className="d-flex hero-search w-100">
          <FaSearch className="me-2 icon-hero-card" />
          <Select
            showSearch
            className="search w-100"
            placeholder="Search Doctor, clinic"
            onSearch={setSearchQuery}
            onChange={handleSelectChange}
            filterOption={false}
            options={filteredOptions}
          />
        </div>
      </div>
      <div className="col-md-3 col-sm-12">
        <button className="btn btn-primary" onClick={handleNavigation}>
          <FaTooth className="mr-1 inline" />
          Make Appointment
        </button>
      </div>
    </div>
  );
};

export default SearchForm;
