import { Button, message } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useCreateBlogCategoryMutation } from "../../../redux/api/blogApi";
import AdminLayout from "../AdminLayout/AdminLayout";

import { FaAngleLeft, FaCheck } from "react-icons/fa";

const AddBlogCategory = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const [mutate, { isLoading }] = useCreateBlogCategoryMutation();

  const onSubmit = (data) => {
    console.log(data);
    mutate(data)
      .unwrap()
      .then((res) => {
        if (res.id) {
          reset();
          message.success("Successfully Added !!");
          navigate("/admin/blog-categories");
        } else throw new Error("Something went wrong");
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.data?.message || "Something went wrong");
      });
  };
  return (
    <AdminLayout>
      <div className="flex items-center gap-3 mb-3">
        <Link
          to="/admin/blog-categories"
          className="getbackButton hover:text-white hover:bg-brand/90"
        >
          {" "}
          <FaAngleLeft className="inline" /> Go Back
        </Link>
        <h3 className="m-0">Add Blog Category</h3>
      </div>

      <div className="card mb-5 p-2 shadow-sm">
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Name</label>
              <input
                placeholder="Enter category name"
                {...register("name", { required: true })}
                className="form-control"
              />
              {errors.title?.type === "required" && (
                <span className="text-danger">Category name is required</span>
              )}
            </div>
          </div>

          <div className="text-center my-3">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {isLoading ? "Saving ..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AddBlogCategory;
