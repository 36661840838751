import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import log from "../../images/doc/info.svg";
import register from "../../images/doc/register.svg";
import SignIn from "./SignIn";
import "./SignInForm.css";
import SignUp from "./SignUp";

const SignInForm = () => {
  const [redirectURL, setRedirectURL] = useState(null);
  const [isSignUp, setSignUp] = useState(false);
  const location = useLocation();
  const pathname = useParams();
  const navigate = useNavigate();

  const handleSignUp = () => {
    setSignUp(true);
    navigate("/register");
  };

  const handleSignIn = () => {
    setSignUp(false);
    navigate("/login");
  };

  useEffect(() => {
    if (location.pathname.startsWith("/register")) {
      setSignUp(true);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get("redirect");
    setRedirectURL(redirectUrl);
  }, [location]);

  return (
    <div
      className={`${
        isSignUp
          ? "signin-signup-container sign-up-mode"
          : "signin-signup-container"
      }`}
    >
      <Link to="/">
        <span className="pageCloseBtn">
          <FaTimes className="inline" />
        </span>
      </Link>
      <div className="forms-container">
        <div className="signIn-singUp">
          <SignIn redirectURL={redirectURL} />
          <SignUp handleSignIn={handleSignIn} doctorId={pathname.id} />
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          {!redirectURL && (
            <div className="content">
              <h3 className="text-white">New here ?</h3>
              <p>
                Sign up and get your appointment booked with the best doctors in
                the town!
              </p>
              <button className="iBtn transparent" onClick={handleSignUp}>
                Sign Up
              </button>
            </div>
          )}
          <img src={`${log}`} alt="" className="pImg" />
        </div>

        <div className="panel right-panel">
          <div className="content">
            <h3 className="text-white">One of us ?</h3>
            <p>
              Sign in to access your account and get the best of our services
            </p>
            <button className="iBtn transparent" onClick={handleSignIn}>
              Sign In
            </button>
          </div>
          <img src={`${register}`} alt="" className="pImg" />
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
