import { message } from "antd";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useResetPasswordMutation,
  useUserLoginMutation,
} from "../../redux/api/authApi";
import { useMessageEffect } from "../../utils/messageSideEffect";
import LoginWithOTP from "./LoginWithOTP";
import VerifyOTP from "./VerifyOTP";

const SignIn = ({ handleResponse, redirectURL }) => {
  const [showLoginWithOTP, setShowLoginWithOTP] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [infoError, setInfoError] = useState("");
  const [show, setShow] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [userLogin, { isError, isLoading, isSuccess, error }] =
    useUserLoginMutation();
  const [forgotEmail, setForgotEmail] = useState("");
  const [
    resetPassword,
    {
      isError: resetIsError,
      isSuccess: resetIsSuccess,
      error: resetError,
      isLoading: resetIsLoading,
    },
  ] = useResetPasswordMutation();

  setTimeout(() => {
    setShow(false);
  }, 10000);

  const onSubmit = async (event) => {
    userLogin({ ...event });
  };

  const onHandleForgotPassword = async (e) => {
    e.preventDefault();
    resetPassword({ email: forgotEmail });
    setForgotEmail("");
    setShowForgotPassword(false);
  };
  useMessageEffect(
    resetIsLoading,
    resetIsSuccess,
    resetIsError,
    resetError,
    "Successfully Reset Password, Please check your Email!!"
  );

  useEffect(() => {
    setShowLoginWithOTP(location.pathname === "/generate-otp");
    setShowVerifyOTP(location.pathname.startsWith("/verify-otp/"));
  }, [location]);

  useEffect(() => {
    if (isError) {
      message.error(error?.data?.message);
      setInfoError(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Logged in");
      navigate(redirectURL ?? "/", { state: location.state ?? {} });
    }
  }, [isError, error, isSuccess, navigate]);

  const handleShowForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  return (
    <>
      {showForgotPassword && (
        <form className="sign-in-form" onSubmit={onHandleForgotPassword}>
          <h2 className="title">Forgot Password</h2>
          <div>To Forgot Your Password Please Enter your email</div>
          <div className="input-field">
            <span className="fIcon">
              <FaEnvelope className="inline" />
            </span>
            <input
              value={forgotEmail !== undefined && forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              placeholder="Enter Your Email"
              type="email"
              required
            />
          </div>
          <div
            onClick={handleShowForgotPassword}
            className="text-bold"
            style={{ cursor: "pointer", color: "#4C25F5" }}
          >
            Stil Remember Password ?
          </div>
          <button className="iBtn" type="submit" value="sign In">
            {resetIsLoading ? (
              <Spinner animation="border" variant="info" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      )}
      {showLoginWithOTP && (
        <LoginWithOTP setShowLoginWithOTP={setShowLoginWithOTP} />
      )}
      {showVerifyOTP && <VerifyOTP />}
      {!showForgotPassword && !showLoginWithOTP && !showVerifyOTP && (
        <form className="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
          {/* <Toast show={show} onClose={() => setShow(!show)} className="signInToast">
                            <Toast.Header>
                                <strong className="mr-auto">Demo credential</strong>
                            </Toast.Header>
                            <Toast.Body>Use this account to sign in as a doctor <br />
                                <hr />
                                <div className='bg-dark text-white p-2 px-3 rounded'>
                                    email : doctor@gmail.com <br />
                                    password : 123456 <br />
                                </div>
                                <hr />
                                <div className='bg-primary p-2 rounded text-white'>
                                    Please do not abuse the facility
                                </div>
                            </Toast.Body>
                        </Toast> */}
          <h2 className="title">Sign in</h2>
          <div className="input-field">
            <span className="fIcon">
              <FaEnvelope className="inline" />
            </span>
            <input
              {...register("email", { required: true })}
              placeholder="Enter your email or phone"
              type="text"
            />
          </div>
          {errors.email && (
            <span className="text-danger">Email is required</span>
          )}
          <div className="input-field">
            <span className="fIcon">
              <FaLock className="inline" />
            </span>
            <input
              {...register("password", { required: true })}
              type="password"
              placeholder="Enter Your Password"
            />
          </div>
          {errors.password && (
            <span className="text-danger">Password is required</span>
          )}
          {infoError && <p className="text-danger">{infoError}</p>}
          <div
            onClick={handleShowForgotPassword}
            className="text-bold"
            style={{ cursor: "pointer", color: "var(--primary)" }}
          >
            Forgot Password ?
          </div>
          <button className="iBtn" type="submit" value="sign In">
            {isLoading ? (
              <Spinner animation="border" variant="info" />
            ) : (
              "Sign In"
            )}
          </button>
          {/* <p className="social-text">Or Sign in with social platforms</p>
          <SocialSignUp handleResponse={handleResponse} isSignup={false} /> */}
          <p className="social-text">Or</p>
          <Link to="/generate-otp" className="text-brand hover:underline">
            Continue with OTP
          </Link>
        </form>
      )}
    </>
  );
};

export default SignIn;
