import { Spin } from "antd";
import React from "react";
import { useGetTermAndConditionQuery } from "../../redux/api/settingApi";
import RenderHTML from "../../utils/RenderHtml";

const PrivacyPolicy = () => {
  const { data, isLoading } = useGetTermAndConditionQuery();
  return (
    <div className=" p-4">
      <h1 className="text-2xl text-center">Privacy Policy</h1>
      {isLoading ? <Spin /> : <RenderHTML htmlString={data?.privacyPolicy} />}
    </div>
  );
};

export default PrivacyPolicy;
