import { Image } from "antd";
import React from "react";
import { useGetAllGalleryQuery } from "../../../redux/api/galleryApi";
import "./index.css";

const Gallery = () => {
  const { data, isLoading } = useGetAllGalleryQuery();
  const images = data?.gallery?.map((item) => item?.imgSrc) || [];
  const imageArray = [...images];
  return (
    <section className="gallery container">
      <div className="text-center mb-5">
        <div className="section-title mb-3">
          <h2>Gallery</h2>
          {/* <p>Lorem ipsum dolor sit amet.</p> */}
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-center">
        <Image.PreviewGroup>
          {imageArray.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-sm-12 mb-4" key={index + 55}>
              <div className="gallery-item">
                <div className="gallery-lightbox d-flex justify-content-center align-items-center">
                  <Image
                    src={item}
                    alt=""
                    className="w-100"
                    style={{
                      objectFit: "cover",
                      maxHeight: "280px",
                      minHeight: "280px",
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Image.PreviewGroup>
      </div>
    </section>
  );
};

export default Gallery;
