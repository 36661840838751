import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Card, Checkbox, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdClear } from "react-icons/md";

const RepeatEventCard = ({
  data,
  setShowRepeat,
  resetEventData,
  onChange,
  repeatEventDateChange,
  weekDaysChange,
}) => {
  const [eventData, setEventData] = useState(data);
  const repeatOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];
  const dailyOptions = Array.from({ length: 30 }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));

  const weekDaysOptions = [
    { label: "Sunday", value: 7 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];

  const getRepeatType = () => {
    switch (eventData.repeatType) {
      case "weekly":
        return "week";
      case "monthly":
        return "month";
      default:
        return "day";
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "repeatType" && e.target.value !== "weekly") {
      weekDaysChange([]);
    }
    onChange(e); // Call external handler if needed
  };

  useEffect(() => {
    setEventData((prev) => ({ ...prev, ...data }));
  }, [data]);

  return (
    <Card
      title={
        <div className="flex justify-end">
          <MdClear
            className="cursor-pointer"
            onClick={() => resetEventData()}
          />
        </div>
      }
      headStyle={{
        borderBottomWidth: 0,
        minHeight: "auto",
        paddingTop: "1rem",
      }}
      className="w-full"
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-4">
          <label className="w-[10rem]">Repeats</label>
          <select
            className="form-control select"
            name="repeatType"
            onChange={handleChange}
            value={eventData.repeatType}
          >
            {repeatOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {eventData.repeatType === "weekly" && (
          <div className="border p-2">
            <h5 className="text-center">On days</h5>
            <div className="flex gap-2">
              <Checkbox.Group
                options={weekDaysOptions}
                value={data.weekDays}
                onChange={weekDaysChange}
              />
            </div>
          </div>
        )}

        <div className="flex gap-4">
          <label className="w-[10rem]">Repeats Every</label>
          <div className="flex gap-2 items-center w-full">
            <select
              className="form-control select max-w-[10rem]"
              name="repeatFrequency"
              onChange={handleChange}
              value={eventData.repeatFrequency} // Set the value of the select
            >
              {dailyOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span>{getRepeatType()}</span>
          </div>
        </div>
        <div className="flex gap-4">
          <label className="w-[10rem]">End Date</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={eventData.repeatUntil}
              name="repeatUntil"
              className="w-full p-1"
              onChange={repeatEventDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </Card>
  );
};

const AddDatePickerEventModal = ({
  open,
  handleClose,
  datePickerEventFormData,
  setDatePickerEventFormData,
  onAddEvent,
  hospitals,
  slotDuration,
}) => {
  const { description, start, end, maximumPatient, repeatEvent, hospitalId } =
    datePickerEventFormData;

  // const [hospitals, setHospitals] = useState([]);
  // const auth = useAuthCheck();

  // useEffect(() => {
  //   if (auth?.data?.hospitalDoctors) {
  //     setHospitals(auth.data.hospitalDoctors);
  //   }
  // }, [auth.data]);

  const onClose = () => {
    resetEventData();
    handleClose();
  };

  const [showRepeat, setShowRepeat] = useState({ activeKey: null });

  const onChange = (event) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const repeatEventChange = (e) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      repeatEvent: {
        ...prevState.repeatEvent,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const weekDaysChange = (e) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      repeatEvent: {
        ...prevState.repeatEvent,
        weekDays: [...e],
      },
    }));
  };

  const repeatEventDateChange = (value) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      repeatEvent: {
        ...prevState.repeatEvent,
        repeatUntil: value ? new Date(value) : null,
      },
    }));
  };

  const resetEventData = (clear = true) => {
    setShowRepeat({ activeKey: null });
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      repeatEvent: {
        repeatType: clear ? null : "daily",
        repeatFrequency: clear ? null : "1",
        repeatUntil: null,
        weekDays: [],
      },
    }));
  };

  const isSameDayDifferentTime = (date1, date2) => {
    if (!date1 || !date2) return false;

    // Check if the day is the same
    const isSameDay =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    // Check if the time is different
    const isDifferentTime =
      date1.getHours() !== date2.getHours() ||
      date1.getMinutes() !== date2.getMinutes() ||
      date1.getSeconds() !== date2.getSeconds();

    return isSameDay && isDifferentTime;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add schedule</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a appointment schedule, please fill in the information below.
        </DialogContentText>
        <Box component="form" className="md:w-[30rem]">
          <FormControl fullWidth className="mt-2">
            <InputLabel id="hospital">Hospital</InputLabel>
            <Select
              labelId="hospital"
              id="hospital"
              label="Hospital"
              name="hospitalId"
              value={hospitalId ?? ""}
              onChange={onChange}
            >
              {hospitals?.map((item) => (
                <MenuItem value={item?.hospital.id} key={item?.hospital.id}>
                  {item?.hospital.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="description"
            value={description}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            onChange={onChange}
          />
          <TextField
            name="maximumPatient"
            value={maximumPatient}
            margin="dense"
            id="maximumPatient"
            label="Maximum Patient"
            type="text"
            fullWidth
            variant="outlined"
            onChange={onChange}
          />
          <p className="text-sm text-muted mb-0">
            {" "}
            Maximum patients you can schedule in your {slotDuration}-minutes
            slot.{" "}
          </p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mb={2} mt={5}>
              <DateTimePicker
                label="Start date"
                value={start}
                ampm={true}
                minutesStep={5}
                onChange={(newValue) =>
                  setDatePickerEventFormData((prevState) => ({
                    ...prevState,
                    start: new Date(newValue),
                    end: new Date(newValue),
                  }))
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>

            <DateTimePicker
              label="End date"
              minDate={start}
              minutesStep={5}
              ampm={true}
              value={end}
              maxDate={start}
              minTime={start}
              onChange={(newValue) =>
                setDatePickerEventFormData((prevState) => ({
                  ...prevState,
                  end: new Date(newValue),
                }))
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Collapse
            collapsible="header"
            ghost
            activeKey={showRepeat.activeKey}
            onChange={(e) => {
              const value = e?.[0] ?? null;
              resetEventData(value === null);
              setShowRepeat({ activeKey: value });
            }}
            items={[
              {
                key: "1",
                label: (
                  <div className="flex items-center gap-2 justify-center">
                    <IoMdAdd />
                    <span>Repeat this event</span>
                  </div>
                ),
                showArrow: false,
                children: (
                  <RepeatEventCard
                    data={repeatEvent}
                    resetEventData={resetEventData}
                    setShowRepeat={setShowRepeat}
                    onChange={repeatEventChange}
                    repeatEventDateChange={repeatEventDateChange}
                    weekDaysChange={weekDaysChange}
                  />
                ),
              },
            ]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="success"
          disabled={!isSameDayDifferentTime(start, end) || !hospitalId}
          onClick={() => {
            onAddEvent();
            resetEventData();
            onClose();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDatePickerEventModal;
