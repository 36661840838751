import React from "react";
import userImg from "../../images/user.png";

const BlogComment = () => {
  return (
    <div className="mx-3" style={{ marginTop: "7rem" }}>
      <h5
        className="mb-5"
        style={{
          textAlign: "center",
          color: "var(--primary)",
          textShadow: "4px 4px 5px rgba(0, 5, 0, 0.28)",
          fontWeight: "600",
        }}
      >
        Comments
      </h5>

      {Array(3)
        .fill(null)
        .map((_item, index) => (
          <div className="comment-card" key={index}>
            <div className="row">
              <div className="col-md-1 col-sm-3 comment-image">
                <img
                  src={userImg}
                  className=""
                  alt="user imge"
                  style={{ width: "100%", borderRadius: "2rem" }}
                />
              </div>
              <div className="col-md-11 col-sm-12">
                <div className="comment-content">
                  <h6>Andrew Simon</h6>
                  <p className="form-text mb-0">August 2023</p>
                </div>

                <p className="mt-2 mb-0 text-justify">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consectetur recusandae sed nemo illum distinctio minus enim
                  hic eum saepe vel similique doloribus adipisci, autem deleniti
                  dolores unde accusantium eos sequi aliquam, temporibus
                  aspernatur iste! Necessitatibus, obcaecati architecto culpa
                  illum fugiat delectus dolor, numquam autem quo suscipit
                  impedit laboriosam id eos!
                </p>
              </div>
            </div>
          </div>
          //   <div className="d-flex gap-3 mb-3" key={index + 5}>
          //     <div>
          //       <img src={userImg}  className="" alt="user imge" />
          //     </div>
          //     <div>
          //       <div className="mb-2">
          //         <h6>Andrew Simon</h6>
          //         <p className="form-text mb-0">August 2023</p>
          //       </div>
          //       <p className="form-text">
          //         Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          //         Consectetur recusandae sed nemo illum distinctio minus enim hic
          //         eum saepe vel similique doloribus adipisci, autem deleniti
          //         dolores unde accusantium eos sequi aliquam, temporibus
          //         aspernatur iste! Necessitatibus, obcaecati architecto culpa
          //         illum fugiat delectus dolor, numquam autem quo suscipit impedit
          //         laboriosam id eos!
          //       </p>
          //     </div>
          //   </div>
        ))}

      <div className="md:p-4" style={{ marginTop: "7rem", marginBottom: "7rem" }}>
        <h5
          className="mb-5"
          style={{
            textAlign: "center",
            color: "var(--primary)",
            textShadow: "4px 4px 5px rgba(0, 5, 0, 0.28)",
            fontWeight: "600",
          }}
        >
          Leave A Comment
        </h5>
        <form className="row form-row">
          <div className="col-md-6 col-sm-12">
            <div className="form-group mb-2 card-label">
              <label>First Name</label>
              <input placeholder="First Name" className="form-control" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Last Name</label>
              <input placeholder="Last Name" className="form-control" />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Subject</label>
              <input placeholder="Subject" className="form-control" />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Comment</label>
              <textarea
                placeholder="Your Comment"
                className="form-control"
                rows={5}
              />
            </div>
          </div>

          <div className="text-center  mt-4">
            <button type="submit" className="more-btn">
              Comment
            </button>
            {/* <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="more-btn"
            >
              Comment
            </Button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogComment;
