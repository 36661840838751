import { Button, Spin, Tabs, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetQuickAppointmentQuery } from "../../../redux/api/doctorApi";
import "./index.css";

const OverView = ({ doctorId, hospital }) => {
  const navigate = useNavigate();

  const handleSlotClick = (date, slotTime) => {
    navigate(`/booking/${doctorId}`, {
      state: {
        selectedHospital: hospital.id,
        date,
        selectedSlot: slotTime,
      },
    });
  };
  return (
    <>
      {/* Doctor's Availability */}
      <div className="sm:col-span-7">
        <div className="flex flex-col gap-2 justify-around overflow-y-hidden ">
          {hospital?.nextAvailableDates.map((slot, index) => (
            <div key={index}>
              <p className="mb-1 font-semibold text-gray-500">{slot.date}</p>
              <div className="flex gap-2 justify-center sm:justify-start max-h-[20rem] overscroll-contain lg:hover:overflow-x-scroll  hover:overflow-x-scroll overflow-x-auto  gutter-stable  p-2">
                {slot.timeSlots.map((time) => (
                  <Tooltip
                    key={time.slot.id}
                    title={time.slot.isSlotFull ? "Max Patient Reached" : ""}
                  >
                    <Button
                      shape="round"
                      onClick={() => handleSlotClick(slot.date, time.slot.time)}
                      disabled={time.slot.isSlotFull}
                    >
                      {time.slot.time}
                    </Button>
                  </Tooltip>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="text-center sm:text-left mt-2 border-t-2">
          <Link to={`/booking/${doctorId}`}>
            <Button shape="round" className="mt-2 py-1 bg-brand text-white">
              View more
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

const Availibility = ({ doctorId }) => {
  const { data, isLoading } = useGetQuickAppointmentQuery({
    day: moment().format("YYYY-MM-DD"),
    limit: 5,
    doctorId,
  });

  if (isLoading) {
    return (
      <div className="container flex items-center justify-center md:min-h-[20rem] p-4">
        <Spin size="large" />
      </div>
    );
  }

  const doctor = data[0] ?? [];

  const items = doctor.hospitals.map((curHospital, index) => {
    return {
      key: `${index + 1}`,
      label: curHospital.name,
      children: <OverView doctorId={doctor.id} hospital={curHospital} />,
    };
  });

  return (
    <div className="col-md-12">
      <div className="widget business-widget">
        <div className="widget-content">
          <div className="listing-hours">
            <h5>Available Hospitals</h5>
            <Tabs defaultActiveKey="1" items={items} />
          </div>
        </div>
      </div>
    </div>
  );
};

// const Availibility = ({ doctorId }) => {
//   const { data, isError, isLoading } = useGetTimeSlotByDoctorIdQuery(doctorId);
//   const today = moment().format("D MMM YYYY");
//   const currentDay = moment().format("dddd").toUpperCase(); // Get current day in uppercase

//   // Find today's slot
//   const todaySlot = data
//     ? data.find((slot) => slot.day.toUpperCase() === currentDay)
//     : null;

//   return (
//     <div className="col-md-6 offset-md-3">
//       <div className="widget business-widget">
//         <div className="widget-content">
//           <div className="listing-hours">
//             {todaySlot ? (
//               <div className="listing-day current d-flex align-items-center">
//                 <div className="day">
//                   Today <span>{today}</span>
//                 </div>
//                 <div className="time-items">
//                   {todaySlot.timeSlot.map((timing, index) =>
//                     timing ? (
//                       <span className="time ms-4" key={index}>
//                         {timing.startTime} - {timing.endTime}
//                       </span>
//                     ) : (
//                       <span className="time ms-4" key={index}>
//                         <span
//                           className="badge bg-danger-light"
//                           style={{ background: "red" }}
//                         >
//                           Closed
//                         </span>
//                       </span>
//                     )
//                   )}
//                   <span className="open-status">
//                     <span
//                       className="badge bg-success-light"
//                       style={{
//                         background: "var(--primary)",
//                         marginLeft: "1rem",
//                       }}
//                     >
//                       Open Now
//                     </span>
//                   </span>
//                 </div>
//               </div>
//             ) : (
//               <div className="listing-day current d-flex align-items-center">
//                 <div className="day">
//                   Today <span>{today}</span>
//                 </div>
//                 <div className="time-items">
//                   <span className="time ms-4">
//                     <span
//                       className="badge bg-danger-light"
//                       style={{ background: "red" }}
//                     >
//                       Closed
//                     </span>
//                   </span>
//                 </div>
//               </div>
//             )}
//             <hr className="mt-2 mb-0" />
//             {data &&
//               data.map((slot, index) => (
//                 <React.Fragment key={index}>
//                   <div className="listing-day d-flex align-items-center">
//                     <div className="day">{slot?.day.toUpperCase()}</div>
//                     <div className="time-items">
//                       {slot.timeSlot.map((timing, idx) =>
//                         timing ? (
//                           <span className="time ms-4" key={idx}>
//                             {timing.startTime} - {timing.endTime}
//                           </span>
//                         ) : (
//                           <span className="time ms-4" key={idx}>
//                             <span
//                               className="badge bg-danger-light"
//                               style={{ background: "red" }}
//                             >
//                               Closed
//                             </span>
//                           </span>
//                         )
//                       )}
//                     </div>
//                   </div>
//                   <hr className="mt-2 mb-0" />
//                 </React.Fragment>
//               ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Availibility;
