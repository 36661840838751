import { Spin, message } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateAppointmentMutation } from "../../redux/api/appointmentApi";
import { addInvoice } from "../../redux/feature/invoiceSlice";

const BookingPending = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [createAppointment, { data, isSuccess, isError, error }] =
    useCreateAppointmentMutation();

  // Ref to ensure the API call runs only once
  const isApiCalled = useRef(false);

  useEffect(() => {
    if (isApiCalled.current) return;

    const state = location.state;

    // Validate the location state
    if (!state || !state.bookAppointment) {
      navigate("/");
      return;
    }

    // Destructure the state and remove unnecessary fields
    const { bookAppointment, encodePhone, ...rest } = state;

    // Trigger the API call only once
    createAppointment(rest);
    isApiCalled.current = true; // Mark as called
  }, [location, navigate, createAppointment]);

  useEffect(() => {
    if (isSuccess && data) {
      // Handle success
      message.success("Successfully Appointment Scheduled");
      dispatch(addInvoice(data));
      navigate(`/booking/success/${data.id}`);
    }

    if (isError) {
      // Handle error
      message.error(error?.data?.message || "Booking appointment failed!");
      navigate("/");
    }
  }, [isSuccess, isError, data, error, navigate, dispatch]);

  return (
    <div className="md:min-h-screen flex justify-center items-center">
      <div className="text-center">
        <Spin size="large" />
        <h4 className="mt-4">Booking your appointment. Please wait...</h4>
      </div>
    </div>
  );
};

export default BookingPending;
