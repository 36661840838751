import React from "react";
import "./Footer.css";
// import logo from "../../../images/logo-footer.svg";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../../images/logo-dummy.png";

const Footer = () => {
  return (
    <footer className="footer position-relative">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <Link to={"/"}>
                    <img src={logo} alt="logo" style={{ maxWidth: "160px" }} />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 col-sm-12">
              <div className="footer-widget footer-menu">
                <h4 className="footer-title">For Patients</h4>
                <ul>
                  <li>
                    <Link to={"/doctors"}>
                      <FaAngleDoubleRight className="icon inline" /> Search for
                      Doctors
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <FaAngleDoubleRight className="icon inline" /> Login
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <FaAngleDoubleRight className="icon inline" /> Register
                    </Link>
                  </li>
                  <li>
                    <Link to={"/doctors"}>
                      <FaAngleDoubleRight className="icon inline" /> Booking
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <FaAngleDoubleRight className="icon inline" /> Patient
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 col-sm-12">
              <div className="footer-widget footer-menu">
                <h4 className="footer-title">For Doctors</h4>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <FaAngleDoubleRight className="icon inline" />{" "}
                      Appointments
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <FaAngleDoubleRight className="icon inline" /> Login
                    </Link>
                  </li>
                  <li>
                    <Link to={"/register"}>
                      <FaAngleDoubleRight className="icon inline" /> Register
                    </Link>
                  </li>
                  <li>
                    <Link to={"/dashboard"}>
                      <FaAngleDoubleRight className="icon inline" /> Doctor
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3 col-sm-12">
              <div className="footer-widget footer-menu">
                <h4 className="footer-title">Contact Us</h4>
                <ul>
                  <li>
                    <Link to={"/about"}>
                      <FaAngleDoubleRight className="icon inline" /> About Us
                    </Link>
                  </li>
                  <li>
                    <p className="text-white">
                      SPINAL PAIN AND REHAB MEDICAL PC BINOD SHAH M.D. 970 N.
                      BROADWAY , SUITE # 310 YONKERS, NY 10701
                    </p>
                  </li>
                  <li>
                    <p className="text-white">
                      <i className="fas fa-phone-alt"></i>
                      (914) 207-1161
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom flex flex-col md:flex-row justify-center items-center py-2">
        <div className="text-white me-2">
          © Copyright {new Date().getFullYear()} All Rights Reserved
        </div>
        <div className="flex items-center gap-2 text-white">
          <Link
            to={"/terms-and-conditions"}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white !underline"
          >
            Terms and Conditions
          </Link>
          |
          <Link
            to={"/privacy-policy"}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white !underline"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
