import { Empty } from "antd";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";
import doctorBg from "../../images/img/doctors-bg.jpg";
import { useGetAllHospitalQuery } from "../../redux/api/hospital";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import "./hospital.css";

const Hospital = () => {
  const { data, role } = useAuthCheck();
  const {
    data: hospitals,
    isLoading,
    isError,
  } = useGetAllHospitalQuery({ id: role === "patient" ? data?.id : undefined });
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError)
    content = (
      <div>
        <Empty />
      </div>
    );

  if (hospitals?.hospitals)
    content = (
      <>
        {hospitals &&
          hospitals?.hospitals?.map((item, key) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={key}>
              <div className="card shadow border-0 mb-5 hospital-card">
                <img
                  src={
                    item?.img ||
                    "https://static.vecteezy.com/system/resources/thumbnails/004/493/181/small_2x/hospital-building-for-healthcare-background-illustration-with-ambulance-car-doctor-patient-nurses-and-medical-clinic-exterior-free-vector.jpg"
                  }
                  alt={item.name}
                  className="img-fluid hospital-card-img"
                  style={{
                    maxHeight: "17rem",
                    height: "15rem",
                    objectFit: "cover",
                  }}
                />
                <div className="card-body min-h-[20rem]   ">
                  <h5 className="card-title ">{item?.name} </h5>
                  <span
                   className="hospital-location"
                  >
                    <FaLocationArrow className="inline" /> {item?.address}
                  </span>
                  <p></p>

                  <p className="hospital-description ">{item?.description}</p>
                  <div className="d-flex">
                    <Link to={`/hospital/${item.id}`} className="btn btn-primary view-details">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );

  console.log(hospitals?.hospitals);

  const weArePleaseStyle = {
    backgroundColor: "antiquewhite",
    height: "60vh",
    background: `url(${doctorBg}) no-repeat`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    padding: "10px",
    position: "relative",
    marginTop: 100,
    marginBottom: 100,
  };

  return (
    <>
      <Header />
      <SubHeader
        title="Hospitals"
        // subtitle="Lorem ipsum dolor sit amet consectetur adipisicing."
      />

      <div className="container" style={{ marginTop: 100 }}>
        <div className="row">
          {content}

          {/* {servicesToShow.map((services) => (
            // {
            //   Array(6).fill(null).map((_item, id) => (
                <div className="col-lg-4 col-md-6 col-sm-6" key={services.id}>
                  <div className="card shadow border-0 mb-5">
                    <img src={services.image || img} alt="" className="img-fluid" style={{ maxHeight: '17rem', height: '15rem', objectFit: 'cover' }} />
                    <div className="p-2">
                      <h4 className="mt-4 mb-2">{services.value}</h4>
                      <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                    </div>
                  </div>
                </div>
              ))
            } */}

          {/* ))} */}
        </div>
      </div>

      <section style={weArePleaseStyle}>
        <div
          className="container"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="row">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <div className="mb-4 section-title text-center">
                  <h2 className="text-uppercase">
                    We are pleased to offer you the
                  </h2>
                  {/* <p className="form-text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Id,
                    sed.
                  </p> */}
                  <Link to={"/doctors"} className="btn-get-started scrollto">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Hospital;
