import React, { useState, useEffect } from "react";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Toast } from "react-bootstrap";
import { message } from "antd";

import style from "./AdminLogin.module.css";
import { useAdminLoginMutation } from "../../../redux/api/authApi";

const AdminSignIn = ({ handleResponse }) => {
  const [infoError, setInfoError] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [adminLogin, { isError, isLoading, isSuccess, error }] =
    useAdminLoginMutation();

  const onSubmit = async (data) => {
    adminLogin(data);
  };

  useEffect(() => {
    console.log(isError, isLoading, isSuccess, error);
    if (isError) {
      message.error(error?.data?.message);
      setInfoError(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Logged in");
      navigate("/admin/dashboard");
    }
  }, [isError, error, isSuccess, navigate]);

  return (
    <main className="flex overflow-hidden overflow-x-auto relative flex-col items-center px-96 pt-14 pb-36 bg-[var(--primary)]  max-md:px-5 max-md:pb-24">
      <header className="flex z-0 flex-col max-w-full text-4xl font-semibold text-center text-black w-[197px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3bdbb6fb7f1fbf713cd11fc393002ecfca359e50d9f2d3fbf7ce7e8203bb3f7?placeholderIfAbsent=true&apiKey=3920dfd3cbf0498b96904feda0b7992f"
          alt="Company logo"
          className="object-contain self-center w-36 max-w-full aspect-[1.87]"
        />
      </header>
      <section className="flex z-0 mt-12 max-w-full bg-white rounded-3xl min-h-auto shadow-[0px_4px_52px_rgba(0,0,0,0.15)] w-[529px] max-md:mt-10">
        <form className="w-100 m-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-100 text-center">
            <h2 className="">Admin Login</h2>
            <div className="mt-3">
              <input
                className="rounded-md bg-gray-200 px-2 py-3 w-[90%]"
                {...register("email", { required: true })}
                placeholder="Email"
                type="email"
              />
            </div>
            {errors.email && (
              <span className="text-danger">This field is required</span>
            )}
            <div className="my-3">
              <input
                className="rounded-md bg-gray-200 px-2 py-3 w-[90%]"
                {...register("password", { required: true })}
                type="password"
                placeholder="Password"
              />
            </div>
            {errors.password && (
              <span className="text-danger">This field is required</span>
            )}
            {infoError && <p className="text-danger">{infoError}</p>}
            <button
              type="submit"
              className="bg-[var(--primary)] text-white my-3 px-4 py-2 rounded-md "
              value="sign In"
            >
              {isLoading ? (
                <Spinner animation="border" variant="info" />
              ) : (
                "Sign In"
              )}
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default AdminSignIn;

// <div>
//       {
//         <form
//           className="flex justify-center items-center h-[100vh] "
//           style={{backgroundColor:'var(--primary)'}}
//           onSubmit={handleSubmit(onSubmit)}
//         >
//           <div className="logo">
//           <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3bdbb6fb7f1fbf713cd11fc393002ecfca359e50d9f2d3fbf7ce7e8203bb3f7?placeholderIfAbsent=true&apiKey=3920dfd3cbf0498b96904feda0b7992f" alt="Company logo" className="object-contain self-center w-36 max-w-full aspect-[1.87]" />

//           </div>
//           <div className={style.adminContainer}>
//             <h2 className={style.title}>Admin Login</h2>
//             <div className={"input-field"}>
//               <span className="fIcon">
//                 <FaEnvelope />
//               </span>
//               <input
//                 className={style.inputs}
//                 {...register("email", { required: true })}
//                 placeholder="Enter Your Email"
//                 type="email"
//               />
//             </div>
//             {errors.email && (
//               <span className="text-danger">This field is required</span>
//             )}
//             <div className="input-field">
//               <span className="fIcon">
//                 <FaLock />
//               </span>
//               <input
//                 {...register("password", { required: true })}
//                 type="password"
//                 placeholder="Enter Your Password"
//               />
//             </div>
//             {errors.password && (
//               <span className="text-danger">This field is required</span>
//             )}
//             {infoError && <p className="text-danger">{infoError}</p>}
//             <button className={style.iBtn} type="submit" value="sign In">
//               {isLoading ? (
//                 <Spinner animation="border" variant="info" />
//               ) : (
//                 "Sign In"
//               )}
//             </button>
//           </div>
//         </form>
//       }
//     </div>
