import { Button, Checkbox, DatePicker, message, Select, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import dImage from "../../../images/doctor.png";
import {
  useCreateDoctorMutation,
  useGetDoctorQuery,
  useUpdateDoctorMutation,
} from "../../../redux/api/doctorApi";
import ImageUpload from "../../UI/form/ImageUpload";

import { FaAngleLeft, FaCheck } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { IoAddOutline } from "react-icons/io5";
import { useGetAllHospitalQuery } from "../../../redux/api/hospital";
import { useGetAllServicesQuery } from "../../../redux/api/servicesApi";
import { useGetAllSpecialistQuery } from "../../../redux/api/specialites";
import "./UpsertDoctor.css";

const EducationSection = ({
  register,
  setValue,
  data,
  errors,
  clearErrors,
}) => {
  const [educations, setEducations] = useState(data);

  useEffect(() => {
    setEducations(data);
  }, [data]);

  const deleteEducation = (index) => {
    const newEducations = [...educations];
    newEducations.splice(index, 1);
    const newArray = [...newEducations];
    setValue("educations", newArray);
    clearErrors("educations");
    setEducations(newArray);
  };

  const handleAddEducation = () => {
    setEducations((prev) => [...prev, {}]);
  };

  return (
    <div className="col-md-12">
      <div className="card mb-2 p-3 mt-2">
        <h6 className="card-title text-secondary">Education</h6>
        {educations.map((item, index) => (
          <div className="row form-row items-center" key={index}>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-2 card-label">
                <label>Degree</label>
                <input
                  defaultValue={item.degree}
                  {...register(`educations.${index}.degree`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.educations?.[index]?.degree && (
                  <span className="text-danger text-sm">
                    Degree is required
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-2 card-label">
                <label>College/Institute</label>
                <input
                  defaultValue={item.institution}
                  {...register(`educations.${index}.institution`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.educations?.[index]?.institution && (
                  <span className="text-danger text-sm">
                    College/Institute is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="form-group mb-2 card-label">
                <label>Year of Completion</label>
                <input
                  defaultValue={item.completionYear}
                  {...register(`educations.${index}.completionYear`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.educations?.[index]?.completionYear && (
                  <span className="text-danger text-sm">Year is required</span>
                )}
              </div>
            </div>

            <div className="col-lg-1">
              <Button
                shape="round"
                type="outline"
                title="delete"
                icon={<MdDelete className="h-4 w-4" />}
                className="mt-2 py-1 b  flex gap-2 items-center text-red-600"
                onClick={() => deleteEducation(index)}
              ></Button>
            </div>
          </div>
        ))}
        <div>
          <Button
            shape="round"
            className="mt-2 py-1 bg-brand text-white flex gap-2 items-center"
            onClick={handleAddEducation}
          >
            <IoAddOutline />
            Add Education
          </Button>
        </div>
      </div>
    </div>
  );
};

const ExperienceSection = ({
  register,
  setValue,
  data,
  errors,
  clearErrors,
}) => {
  const [experiences, setExperiences] = useState(data);

  useEffect(() => {
    setExperiences(data);
  }, [data]);

  const deleteExperience = (index) => {
    const newExperiences = [...experiences];
    newExperiences.splice(index, 1);
    const newArray = [...newExperiences];
    setValue("experiences", newArray);
    clearErrors("experiences");
    setExperiences(newArray);
  };

  const handleAddExperience = () => {
    setExperiences((prev) => [...prev, {}]);
  };

  return (
    <div className="col-md-12">
      <div className="card mb-2 p-3 mt-2">
        <h6 className="card-title text-secondary">Experience</h6>
        {experiences.map((item, index) => (
          <div className="row form-row items-center" key={item.id}>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-2 card-label">
                <label>Hospital Name</label>
                <input
                  defaultValue={item?.hospitalName}
                  {...register(`experiences.${index}.hospitalName`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.experiences?.[index]?.hospitalName && (
                  <span className="text-danger text-sm">
                    Hospital name is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-2 card-label">
                <label>From</label>
                <input
                  defaultValue={item?.startDate}
                  {...register(`experiences.${index}.startDate`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.experiences?.[index]?.startDate && (
                  <span className="text-danger text-sm">
                    Start date is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="form-group mb-2 card-label">
                <label>To</label>
                <input
                  defaultValue={item?.endDate}
                  {...register(`experiences.${index}.endDate`)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="form-group mb-2 card-label">
                <label>Designation</label>
                <input
                  defaultValue={item?.designation}
                  {...register(`experiences.${index}.designation`)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-1">
              <Button
                shape="round"
                type="outline"
                title="delete"
                icon={<MdDelete className="h-4 w-4" />}
                className="mt-2 py-1 b  flex gap-2 items-center text-red-600"
                onClick={() => deleteExperience(index)}
              ></Button>
            </div>
          </div>
        ))}
        <div>
          <Button
            shape="round"
            className="mt-2 py-1 bg-brand text-white flex gap-2 items-center"
            onClick={handleAddExperience}
          >
            <IoAddOutline />
            Add Experience
          </Button>
        </div>
      </div>
    </div>
  );
};

const AwardSection = ({ register, setValue, errors, data, clearErrors }) => {
  const [awards, setAwards] = useState(data);

  useEffect(() => {
    setAwards(data);
  }, [data]);

  const deleteAward = (index) => {
    const newAwards = [...awards];
    newAwards.splice(index, 1);
    const newArray = [...newAwards];
    setValue("awards", newArray);
    clearErrors("awards");
    setAwards(newArray);
  };

  const handleAddAward = () => {
    setAwards((prev) => [...prev, {}]);
  };

  return (
    <div className="col-md-12">
      <div className="card mb-2 p-3 mt-2">
        <h6 className="card-title text-secondary">Awards</h6>
        {awards.map((item, index) => (
          <div className="row form-row items-center" key={item.id}>
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>Awards</label>
                <input
                  defaultValue={item?.title}
                  {...register(`awards.${index}.title`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.awards?.[index]?.title && (
                  <span className="text-danger text-sm">
                    Award name is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group mb-2 card-label">
                <label>Year</label>
                <input
                  defaultValue={item?.year}
                  {...register(`awards.${index}.year`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.awards?.[index]?.year && (
                  <span className="text-danger text-sm">Year is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-1">
              <Button
                shape="round"
                type="outline"
                title="delete"
                icon={<MdDelete className="h-4 w-4" />}
                className="mt-2 py-1 b  flex gap-2 items-center text-red-600"
                onClick={() => deleteAward(index)}
              ></Button>
            </div>
          </div>
        ))}
        <div>
          <Button
            shape="round"
            className="mt-2 py-1 bg-brand text-white flex gap-2 items-center"
            onClick={handleAddAward}
          >
            <IoAddOutline />
            Add Award
          </Button>
        </div>
      </div>
    </div>
  );
};

const RegistrationSection = ({
  register,
  setValue,
  errors,
  data,
  clearErrors,
}) => {
  const [registrations, setRegistrations] = useState(data);

  useEffect(() => {
    setRegistrations(data);
  }, [data]);

  const deleteRegistration = (index) => {
    const newRegistrations = [...registrations];
    newRegistrations.splice(index, 1);
    const newArray = [...newRegistrations];
    setValue("registrations", newArray);
    clearErrors("registrations");
    setRegistrations(newArray);
  };

  const handleAddRegistration = () => {
    setRegistrations((prev) => [...prev, {}]);
  };

  return (
    <div className="col-md-12">
      <div className="card mb-2 p-3 mt-2">
        <h6 className="card-title text-secondary">Registrations</h6>
        {registrations.map((item, index) => (
          <div className="row form-row items-center" key={item.id}>
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>Registrations</label>
                <input
                  defaultValue={item?.title}
                  {...register(`registrations.${index}.title`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.registrations?.[index]?.title && (
                  <span className="text-danger text-sm">
                    Registration name is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-group mb-2 card-label">
                <label>Year</label>
                <input
                  defaultValue={item?.year}
                  {...register(`registrations.${index}.year`, {
                    required: true,
                  })}
                  className="form-control"
                />
                {errors?.registrations?.[index]?.year && (
                  <span className="text-danger text-sm">Year is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-1">
              <Button
                shape="round"
                type="outline"
                title="delete"
                icon={<MdDelete className="h-4 w-4" />}
                className="mt-2 py-1 b  flex gap-2 items-center text-red-600"
                onClick={() => deleteRegistration(index)}
              ></Button>
            </div>
          </div>
        ))}
        <div>
          <Button
            shape="round"
            className="mt-2 py-1 bg-brand text-white flex gap-2 items-center"
            onClick={handleAddRegistration}
          >
            <IoAddOutline />
            Add Registration
          </Button>
        </div>
      </div>
    </div>
  );
};

const UpsertDoctor = ({ setShowForm, id }) => {
  const { pathname } = useLocation();
  const [selectedService, setSelectedServices] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState([]);
  const [selectedhospital, setSelectedHospital] = useState([]);
  const [updateDoctor, { isLoading, isSuccess, isError, error }] =
    useUpdateDoctorMutation();
  const { data: specialitestList } = useGetAllSpecialistQuery();
  const { data: hospitalList } = useGetAllHospitalQuery();
  const { data: ServicesList } = useGetAllServicesQuery();

  const [
    createDoctor,
    {
      isLoading: createisLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
    },
  ] = useCreateDoctorMutation();
  const {
    data,
    isLoading: isLoadingDoctor,
    isError: isErrorDoctor,
  } = useGetDoctorQuery(id, {
    skip: !id, // Skip the query if `id` is null
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({});
  const [userId, setUserId] = useState("");
  const [selectValue, setSelectValue] = useState({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (id && data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        gender,
        dob,
        biography,
        receiveSMS,
        clinicName,
        clinicAddress,
        address,
        city,
        state,
        country,
        postalCode,
        price,
        degree,
        college,
        completionYear,
        experienceHospitalName,
        expericenceStart,
        expericenceEnd,
        designation,
        award,
        awardYear,
        registration,
        year,
        img,
        active,
        specialistsList = [],
        hospitalDoctors = [],
        servicesList = [],
        slotDuration,
      } = data;

      reset({
        firstName,
        lastName,
        email,
        phone,
        gender,
        dob,
        biography,
        receiveSMS,
        clinicName,
        clinicAddress,
        address,
        city,
        state,
        country,
        postalCode,
        price,
        degree,
        college,
        completionYear,
        experienceHospitalName,
        expericenceStart,
        expericenceEnd,
        designation,
        award,
        awardYear,
        registration,
        year,
        active: active ?? false,
        slotDuration,
      });

      setSelectedServices(
        servicesList?.map(({ service }) => ({
          label: service.name,
          value: service.id,
        })) || []
      );

      setSelectedHospital(
        hospitalDoctors?.map(({ hospital }) => ({
          label: hospital.name,
          value: hospital.id,
        })) || []
      );
      setSelectedSpecialist(
        specialistsList?.map(({ specialist }) => ({
          label: specialist.name,
          value: specialist.id,
        })) || []
      );
      setDate(dob ? moment(dob) : null);
      setSelectedImage(img || dImage);
    } else {
      reset();
    }

    return () => {
      reset();
    };
  }, [id, data, setValue, reset]);

  const handleChange = (e) => {
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
  };
  const onChange = (date, dateString) => {
    if (date) {
      setDate(moment(dateString));
      // Save the moment object
    } else {
      setDate(null); // Clear the date
    }
  };

  const onSubmit = async (data) => {
    // Create a copy of the data object
    const obj = data;

    // Convert obj.price to a string if it exists
    obj.price && obj.price.toString();

    // Merge obj with selectValue
    const newObj = { ...obj, ...selectValue };

    // Add date as 'dob' property to newObj if date exists
    date && (newObj["dob"] = date);

    // Convert selectedItems array to comma-separated string and add to newObj as 'services' property
    newObj["services"] = Array.isArray(selectedService)
      ? selectedService.map((service) => {
          return typeof service == "object" ? service.value : service;
        })
      : null;
    newObj["specialists"] = Array.isArray(selectedSpecialist)
      ? selectedSpecialist.map((speciallist) => {
          return typeof speciallist == "object"
            ? speciallist.value
            : speciallist;
        })
      : null;
    newObj["hospitals"] = Array.isArray(selectedhospital)
      ? selectedhospital.map((hospital) => {
          return typeof hospital == "object" ? hospital.value : hospital;
        })
      : null;

    // Create a new object with only non-empty values from newObj
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );

    console.log(changedValue);

    // Create a new FormData object
    const formData = new FormData();

    // Append selectedImage as 'file' to formData if selectedImage exists
    selectedImage && formData.append("file", file);

    // Convert changedValue object to JSON string
    const changeData = JSON.stringify(changedValue);

    // Append changeData as 'data' to formData
    formData.append("data", changeData);
    // If id exists, update doctor using updateDoctor mutation

    if (id) {
      await updateDoctor({ data: formData, id });
    } else {
      // Otherwise, create a new doctor using createDoctor mutation
      await createDoctor({ data: formData });
    }

    // Hide the form after submission
    if (setShowForm) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.message);
    }
    if (isSuccess) {
      message.success("Successfully Changed Saved !");
    }
  }, [isLoading, isError, error, isSuccess]);
  useEffect(() => {
    if (!createisLoading && createIsError) {
      message.error(error?.data?.message);
    }
    if (createIsSuccess) {
      message.success("Successfully  Saved !");
    }
  }, [createisLoading, createIsError, createIsSuccess]);

  if (isLoadingDoctor)
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );

  return (
    <div style={{ marginBottom: "10rem" }}>
      <div className="w-100 rounded mb-5 p-2" style={{ background: "#f8f9fa" }}>
        <div className="top">
          <h5 className="text-title mb-2 mt-3">
            {id ? "Update" : "Create"} Doctor
          </h5>

          {setShowForm && (
            <button
              className="getbackButton"
              onClick={() => {
                setShowForm(false);
              }}
            >
              {" "}
              <FaAngleLeft className="inline" /> Go Back
            </button>
          )}
        </div>
        <p
          className={`flex gap-2 items-center ${
            data?.verified ? "text-green-500" : "text-red-500"
          }`}
        >
          {data?.verified ? (
            <>
              <FaCheck aria-label="Verified" /> Email verified
            </>
          ) : (
            <>
              <GiCancel aria-label="Not Verified" /> Email not verified
            </>
          )}
        </p>

        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 mb-5">
            <div className="form-group">
              <div className="change-avatar d-flex gap-2 align-items-center">
                <Link to={"/"} className="my-3 patient-img">
                  <img
                    src={selectedImage ? selectedImage : data?.img || dImage}
                    alt=""
                  />
                </Link>
                <div className="mt-3">
                  <ImageUpload
                    setSelectedImage={setSelectedImage}
                    setFile={setFile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                First Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.firstName}
                {...register("firstName")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.lastName}
                {...register("lastName")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Email</label>
              <input
                defaultValue={data?.email}
                {...register("email")}
                className="form-control"
              />
            </div>
          </div>
          {!id && (
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>Password</label>
                <input
                  type="password"
                  {...register("password")}
                  className="form-control"
                />
              </div>
            </div>
          )}

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Phone Number</label>
              <input
                defaultValue={data?.phone}
                {...register("phone")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Gender</label>
              <select
                className="form-control select"
                onChange={handleChange}
                name="gender"
                value={watch("gender")} // Set the value of the select
              >
                <option value={""}>Select</option>
                <option value={"male"}>male</option>
                <option value={"female"}>female</option>
              </select>
              {/* <select
                className="form-control select"
                {...register("gender")}
                defaultValue={data?.gender || ""}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Date of Birth</label>
              <DatePicker
                onChange={onChange}
                format={"YYYY-MM-DD"}
                style={{ width: "100%", padding: "6px" }}
                value={date} // Set the value of the DatePicker
              />
            </div>
          </div>
          {pathname.startsWith("/admin") && (
            <div className="col-md-6">
              <div className="form-group mb-2 flex gap-2">
                <Checkbox
                  {...register("active")}
                  checked={watch("active")}
                  onChange={(e) => {
                    setValue("active", e.target.checked);
                    setSelectValue({
                      ...selectValue,
                      active: e.target.checked,
                    });
                  }}
                >
                  Active
                </Checkbox>
              </div>
            </div>
          )}

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">SMS Consent</h6>
              <div className="row form-row">
                <div className="col-md-12">
                  <Checkbox
                    checked={watch("receiveSMS")}
                    {...register("receiveSMS", {
                      onChange: (e) => setValue("receiveSMS", e.target.checked),
                    })}
                  >
                    <span className="text-sm text-muted">
                      We can send you text message and email reminders, as well
                      as updates about your upcoming appointments. By checking
                      the box, you consent to receiving these notifications. You
                      may update your preferences at any time.
                    </span>
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 mt-2">
              <div className="card-body">
                <h6 className="card-title text-secondary">About Me</h6>
                <div className="form-group mb-2 card-label">
                  <label>Biography</label>
                  <textarea
                    defaultValue={data?.biography}
                    {...register("biography")}
                    className="form-control"
                    rows={5}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Hospital Info</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Hospitals</label>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      value={selectedhospital}
                      onChange={setSelectedHospital}
                      options={hospitalList?.hospitals.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Slot Info</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Default slot duration</label>
                    <div className="flex gap-2 items-center">
                      <input
                        type="number"
                        className="form-control md:max-w-[10rem]"
                        defaultValue={data?.slotDuration}
                        {...register("slotDuration")}
                      />
                      <span className="text-muted text-sm">(in minutes)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-12">
                        <div className="card mb-2 p-3 mt-2">
                            <h6 className="card-title text-secondary">Clinic Info</h6>
                            <div className="row form-row">
                                <div className="col-md-6">
                                    <div className="form-group mb-2 card-label">
                                        <label>Clinic Name</label>
                                        <input defaultValue={data?.clinicName} {...register("clinicName")} className="form-control" rows={5} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-2 card-label">
                                        <label>Clinic Address</label>
                                        <input type="text" defaultValue={data?.clinicAddress} {...register("clinicAddress")} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Contact Details</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Address Line</label>
                    <input
                      defaultValue={data?.address}
                      {...register("address")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>City</label>
                    <input
                      defaultValue={data?.city}
                      {...register("city")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>State / Province</label>
                    <input
                      defaultValue={data?.state}
                      {...register("state")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Country</label>
                    <input
                      defaultValue={data?.country}
                      {...register("country")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Postal Code</label>
                    <input
                      defaultValue={data?.postalCode}
                      {...register("postalCode")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Pricing</h6>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>30 Min Fee</label>
                    <input
                      defaultValue={data?.price}
                      {...register("price")}
                      type="number"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">
                Services and Specialization
              </h6>
              <div className="row form-row">
                <div className="form-group mb-2 card-label">
                  <label>Services</label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedService}
                    onChange={setSelectedServices}
                    options={ServicesList?.services?.map((service) => ({
                      label: service.name,
                      value: service.id,
                    }))}
                  />
                  <small className="form-text text-muted">
                    Note : Type & Press enter to add new services
                  </small>
                </div>
                <div className="form-group mb-2 card-label">
                  <label>Specialization </label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedSpecialist}
                    onChange={setSelectedSpecialist}
                    options={specialitestList?.specialist?.map(
                      (specialist) => ({
                        label: specialist.name,
                        value: specialist.id,
                      })
                    )}
                  />
                  {/* <input
                    defaultValue={data?.specialization}
                    {...register("specialization")}
                    className="input-tags form-control"
                    placeholder="Enter Specialization"
                  />
                  <small className="form-text text-muted">
                    Note : Type & Press enter to add new specialization
                  </small> */}
                </div>
              </div>
            </div>
          </div>

          <EducationSection
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            data={data?.educations ?? []}
          />

          <ExperienceSection
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            data={data?.experiences ?? []}
          />

          <AwardSection
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            data={data?.awards ?? []}
          />

          <RegistrationSection
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
            data={data?.registrations ?? []}
          />

          {/* <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Education</h6>
              <div className="row form-row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Degree</label>
                    <input
                      defaultValue={data?.degree}
                      {...register("degree")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>College/Institute</label>
                    <input
                      defaultValue={data?.college}
                      {...register("college")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Year of Completion</label>
                    <input
                      defaultValue={data?.completionYear}
                      {...register("completionYear")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Experience</h6>
              <div className="row form-row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Hospital Name</label>
                    <input
                      defaultValue={data?.experienceHospitalName}
                      {...register("experienceHospitalName")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>From</label>
                    <input
                      defaultValue={data?.expericenceStart}
                      {...register("expericenceStart")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>To</label>
                    <input
                      defaultValue={data?.expericenceEnd}
                      {...register("expericenceEnd")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Designation</label>
                    <input
                      defaultValue={data?.designation}
                      {...register("designation")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Awards</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Awards</label>
                    <input
                      defaultValue={data?.award}
                      {...register("award")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Year</label>
                    <input
                      defaultValue={data?.awardYear}
                      {...register("awardYear")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Registrations</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Registrations</label>
                    <input
                      defaultValue={data?.registration}
                      {...register("registration")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Year</label>
                    <input
                      defaultValue={data?.year}
                      {...register("year")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="text-center my-3 ">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {id
                ? isLoading
                  ? "Updating ..."
                  : "Update Changes"
                : createisLoading
                ? "Saving ..."
                : "Save Changes"}
            </Button>
            {setShowForm && (
              <Button
                htmlType="button"
                className="ms-2"
                size="large"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpsertDoctor;
