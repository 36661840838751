import { Button, Empty, message, Spin, Steps, Tooltip } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaArchway } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import img from "../../../images/doc/doctor 3.jpg";
import { useCreateAppointmentMutation } from "../../../redux/api/appointmentApi";
import { useGenerateCodeMutation } from "../../../redux/api/authApi";
import { useGetDoctorQuery } from "../../../redux/api/doctorApi";
import { useGetAppointmentTimeQuery } from "../../../redux/api/timeSlotApi";
import { addInvoice } from "../../../redux/feature/invoiceSlice";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { SettingContext } from "../../context/setting";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import CheckoutPage from "../BookingCheckout/CheckoutPage";
import PersonalInformation from "../PersonalInformation";
import SelectDateAndTimeDemo from "../SelectDateAndTimeDemo";
import "./index.css";

const DoctorBooking = () => {
  const setting = useContext(SettingContext);
  const dispatch = useDispatch();
  let initialValue = {
    paymentMethod: "Insurance",
    paymentType: "One-time",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reasonForVisit: "",
    description: "",
    address: "",
    nameOnCard: "",
    cardNumber: "",
    expiredMonth: "",
    cardExpiredYear: "",
    cvv: "",
    insurancePlanId: "",
    receiveSMS: true,
  };
  const { data: loggedInUser, role } = useAuthCheck();
  const [current, setCurrent] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectDay, setSelecDay] = useState("");
  const [selectTime, setSelectTime] = useState("");
  const [isCheck, setIsChecked] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [hospitalId, setHospitalId] = useState("");
  const [
    createAppointment,
    {
      data: appointmentData,
      isSuccess: createIsSuccess,
      isError: createIsError,
      error: createError,
      isLoading: createIsLoading,
    },
  ] = useCreateAppointmentMutation();

  const { doctorId } = useParams();
  const navigation = useNavigate();
  const { data, isLoading, isError, error } = useGetDoctorQuery(doctorId);
  const {
    data: time,
    refetch,
    isLoading: dIsLoading,
    isFetching: dIsFetching,
    isError: dIsError,
    error: dError,
  } = useGetAppointmentTimeQuery({
    day: selectDay,
    id: doctorId,
    hospitalId,
  });

  const timeslot = time?.map((item) => {
    return item?.slot?.time;
  });

  const [selectValue, setSelectValue] = useState(initialValue);
  const [IsdDisable, setIsDisable] = useState(true);
  const [IsConfirmDisable, setIsConfirmDisable] = useState(true);
  const location = useLocation();

  const { loading: authLoading, authChecked } = useAuthCheck();

  const [generateCodeMutation] = useGenerateCodeMutation();
  // const [disabledTimeslot, setDisabledTimeslot] = useState([]);

  // const { data: patient } = useGetAllDoctorAppointmentsQuery();

  // useEffect(() => {
  //   if (patient) {
  //     console.log(patient);
  //     const appointmentCounts = {};
  //     patient.forEach((item) => {
  //       if (item.scheduleDate === selectedDate) {
  //         const slotTime = item.scheduleTime;
  //         if (!appointmentCounts[slotTime]) {
  //           appointmentCounts[slotTime] = 0;
  //         }
  //         appointmentCounts[slotTime]++;
  //       }
  //     });
  //     const newDisabledTimeslot = time
  //       ?.filter(
  //         (item) =>
  //           appointmentCounts[item.slot.time] >= item.slot.maximumPatient
  //       )
  //       .map((item) => item.slot.time);

  //     setDisabledTimeslot(newDisabledTimeslot ?? []);
  //   }
  // }, [patient, time, selectedDate]);

  const [paymentMethod, setPaymentMethod] = useState("Credit");

  const handleChange = (e) => {
    if (e?.target) {
      setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
    } else {
      // Handle cases where data is directly passed as an object (e.g., custom components)
      setSelectValue((prevValue) => ({
        ...prevValue,
        ...e,
      }));
    }
  };
  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      phone,
      nameOnCard,
      cardNumber,
      expiredMonth,
      cardExpiredYear,
      cvv,
      reasonForVisit,
    } = selectValue;
    const isInputEmpty =
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !reasonForVisit ||
      !hospitalId;
    if (setting[0].isPaymentEnabled) {
      let isConfirmInputEmpty = false;
      if (paymentMethod === "Credit") {
        isConfirmInputEmpty =
          !nameOnCard ||
          !cardNumber ||
          !expiredMonth ||
          !cardExpiredYear ||
          !cvv ||
          !isCheck;
      } else if (paymentMethod === "Cash") {
        isConfirmInputEmpty = false;
      } else if (paymentMethod === "Insurance") {
        isConfirmInputEmpty = false;
      }
      setIsConfirmDisable(isConfirmInputEmpty);
    } else {
      setIsConfirmDisable(isInputEmpty);
    }
    setIsDisable(isInputEmpty);
  }, [selectValue, isCheck, paymentMethod]);

  const setDateChange = (dateString) => {
    setSelectedDate(dateString.split(" ")[0]);
    const date = moment(dateString).format("YYYY-MM-DD");

    setSelecDay(date);
  };

  const handleDateChange = (_date, dateString) => {
    if (!dateString) {
      setSelectedDate(dateString);
      setSelecDay(dateString);
      return;
    }
    setSelectedDate(dateString.split(" ")[0]);
    const date = moment(dateString).format("YYYY-MM-DD");

    setSelecDay(date);
    refetch();
  };
  const handleHospitalChange = (hospitalId) => {
    setHospitalId(hospitalId);
  };
  const disabledDateTime = (current) =>
    current && current < moment().add(1, "day").startOf("day");
  const handleSelectTime = (date) => {
    setSelectTime(date);
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    const { selectedHospital, selectedSlot, date } = location.state || {};

    if (!selectedHospital || !selectedSlot || !date) return;

    setSelectedDate(date);
    setSelecDay(date);
    setHospitalId(selectedHospital);
    setSelectTime(selectedSlot);

    next();
  }, [location]);

  let dContent = null;

  const isFetchingTimeSlot = dIsLoading || dIsFetching;
  if (isFetchingTimeSlot)
    dContent = (
      <div>
        <Spin size="large" />
      </div>
    );

  if (!isFetchingTimeSlot && dIsError)
    dContent = <div>Something went Wrong!</div>;
  if (!isFetchingTimeSlot && !dIsError && time?.length === 0)
    dContent = <Empty children="Doctor Is not Available" />;

  if (!isFetchingTimeSlot && !dIsError && time?.length > 0)
    dContent = (
      <>
        {time &&
          time.map((item) => (
            <div className="col-md-4" key={item.slot.id}>
              <Tooltip
                title={item?.slot?.isSlotFull ? "Max Patient Reached" : ""}
              >
                <Button
                  type={item?.slot?.time === selectTime ? "primary" : "default"}
                  shape="round"
                  size="large"
                  className="mb-3"
                  onClick={() => handleSelectTime(item?.slot?.time)}
                  disabled={item?.slot?.isSlotFull}
                >
                  {item?.slot?.time}
                </Button>
              </Tooltip>
            </div>
          ))}
      </>
    );

  //What to render
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (!isLoading && !isError && data?.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = (
      <>
        <div className="booking-doc-img my-3 mb-3 rounded">
          <Link to={`/doctors/${data?.id}`}>
            <img src={data?.img || img} alt="" />
          </Link>
          <div className="text-start">
            <Link
              to={`/doctors/${data?.id}`}
              style={{ textDecoration: "none" }}
            >
              Dr. {data?.firstName + " " + data?.lastName}
            </Link>
            <p className="form-text mb-0">
              <FaArchway />{" "}
              {data?.servicesList.map(({ service }) => service.name).join(", ")}
            </p>
          </div>
        </div>
      </>
    );

  let steps = [
    {
      title: "Select Appointment Date & Time",
      content: (
        <SelectDateAndTimeDemo
          content={content}
          details={data}
          date={selectDay || selectedDate}
          handleDateChange={handleDateChange}
          disabledDateTime={disabledDateTime}
          selectedDate={selectedDate}
          dContent={dContent}
          selectTime={selectTime}
          handleSelectTime={handleSelectTime}
          setDateChange={setDateChange}
          handleHospitalChange={handleHospitalChange}
        />
      ),
    },
    {
      title: "Patient Information",
      content: (
        <PersonalInformation
          handleChange={handleChange}
          selectValue={selectValue}
          setPatientId={setPatientId}
        />
      ),
    },
  ];
  if (setting[0].isPaymentEnabled) {
    steps.push({
      title: "Payment",
      content: (
        <CheckoutPage
          handleChange={handleChange}
          selectValue={selectValue}
          isCheck={isCheck}
          setPaymentMethod={setPaymentMethod}
          setIsChecked={setIsChecked}
          data={data}
          selectedDate={selectedDate}
          selectTime={selectTime}
        />
      ),
    });
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleConfirmSchedule = () => {
    const obj = {};
    obj.patientInfo = {
      firstName: selectValue.firstName,
      lastName: selectValue.lastName,
      email: selectValue.email,
      phone: selectValue.phone,
      receiveSMS: selectValue.receiveSMS,
      scheduleDate: selectedDate,
      scheduleTime: selectTime,
      doctorId: doctorId,
      hospitalId: hospitalId,
      description: selectValue.description,
      reasonForVisit: selectValue.reasonForVisit,
      recentTest: selectValue.recentTest,
      currentMedications: selectValue.currentMedications,
      allergies: selectValue.allergies,
      existingConditions: selectValue.existingConditions,
      patientId: role !== "" && role === "patient" ? patientId : undefined,
    };
    setting[0].isPaymentEnabled &&
      (obj.payment = {
        paymentType: selectValue.paymentType,
        paymentMethod: paymentMethod,
        cardNumber: selectValue.cardNumber,
        cardExpiredYear: selectValue.cardExpiredYear,
        cvv: selectValue.cvv,
        expiredMonth: selectValue.expiredMonth,
        nameOnCard: selectValue.nameOnCard,
        insurancePlanId: selectValue.insurancePlanId,
      });

    // check if user is logged in or not
    if (!authChecked) {
      // verify with otp
      generateCodeMutation({ phone: selectValue.phone })
        .unwrap()
        .then((res) => {
          const bookingInfo = {
            ...obj,
            bookAppointment: true,
            encodePhone: res.encodePhone,
          };
          message.success(res.message);
          navigation(
            `/verify-otp/${res.token}?redirect=/booking/pending/${doctorId}`,
            {
              state: bookingInfo,
            }
          );
        })
        .catch((error) => {
          message.error(error?.data?.message || "Failed to send OTP code");
        });

      return;
    }
    createAppointment(obj);
  };

  useEffect(() => {
    if (createIsSuccess) {
      message.success("Succcessfully Appointment Scheduled");
      setSelectValue(initialValue);
      dispatch(addInvoice({ ...appointmentData }));
      navigation(`/booking/success/${appointmentData.id}`);
    }
    if (createIsError) {
      message.error(error?.data?.message);
    }
  }, [createIsSuccess, createError]);

  if (authLoading) {
    return (
      <div className="d-flex items-center justify-center md:h-screen">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div
        className="container"
        style={{ marginBottom: "12rem", marginTop: "6rem" }}
      >
        <Steps current={current} items={items} />
        <div className=" m-3">{steps[current].content}</div>
        <div className="text-end mx-3">
          {current < steps.length - 1 && (
            <Button
              type="primary"
              disabled={
                current === 0
                  ? selectTime && hospitalId
                    ? false
                    : true
                  : IsdDisable || !selectTime
              }
              onClick={() => next()}
            >
              Next
            </Button>
          )}

          {current === steps.length - 1 && (
            <Button
              type="primary"
              disabled={IsConfirmDisable}
              loading={createIsLoading}
              onClick={handleConfirmSchedule}
            >
              Confirm
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DoctorBooking;
