import { message } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useGenerateCodeMutation } from "../../redux/api/authApi";

const LoginWithOTP = () => {
  const navigate = useNavigate();
  const [mutate] = useGenerateCodeMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: "",
    },
  });

  const onSubmit = (data) => {
    mutate(data)
      .unwrap()
      .then((res) => {
        message.success(res.message);
        const state = {
          encodePhone: res.encodePhone,
        };
        navigate(`/verify-otp/${res.token}`, { state });
      })
      .catch((error) => {
        message.error(error?.data?.message || "Something went wrong");
      });
  };
  return (
    <form className="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="title">Sign in</h2>
      <div className="input-field">
        <span className="fIcon">
          <FaPhoneAlt className="inline" />
        </span>
        <input
          {...register("phone", { required: true })}
          placeholder="Enter your phone number"
          type="text"
          onInput={(e) => {
            e.target.value = e.target.value.replace(/(?!^\+)[^\d]/g, "");
          }}
        />
      </div>
      {errors.phone && (
        <span className="text-danger">Phone number is required</span>
      )}
      <div>
        <button className="iBtn" type="submit">
          Send OTP
        </button>
      </div>
      <p className="social-text">Or</p>
      <Link to="/login" className="text-brand">
        Login with password
      </Link>
    </form>
  );
};

export default LoginWithOTP;
