import { Button, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetQuickAppointmentQuery } from "../../redux/api/doctorApi";
import HospitalMapLink from "../common/HospitalMapLink";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SearchBar from "./Home/SearchBar";

const DoctorCard = ({ profile }) => {
  const navigate = useNavigate();
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [availability, setAvailability] = useState([]);

  const handleHospitalChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setSelectedHospital(null);
      setAvailability([]);
      return;
    }
    const hospital = hospitals.find((h) => h.id === value);
    setSelectedHospital(hospital);
    setAvailability(
      hospital.nextAvailableDates.filter((d) => d.slot.isSlotFull)
    );
  };

  const calculateExperience = (year) => {
    const years = new Date().getFullYear() - year;
    return years;
  };

  const handleSlotClick = (date, slotTime) => {
    navigate(`/booking/${profile.id}`, {
      state: {
        selectedHospital: selectedHospital.id,
        date,
        selectedSlot: slotTime,
      },
    });
  };

  useEffect(() => {
    const filterHospitalWithDates = profile.hospitals.filter(
      (h) => h.nextAvailableDates.length > 0
    );
    setHospitals(filterHospitalWithDates);
    if (filterHospitalWithDates.length > 0) {
      setSelectedHospital(filterHospitalWithDates[0]);
      setAvailability(
        filterHospitalWithDates[0].nextAvailableDates.map((d) => ({
          ...d,
          timeSlots: d.timeSlots.filter((t) => !t.slot.isSlotFull),
        }))
      );
    }
  }, [profile]);

  return (
    <>
      <div className="p-3 bg-white rounded-md shadow-md grid grid-cols-1 sm:grid-cols-12 gap-5 oipuhui">
        {/* Doctor Info */}
        <div className="sm:col-span-5 flex flex-col sm:flex-row items-center sm:items-start gap-3">
          <div className="w-full flex flex-col items-center sm:items-start">
            <img
              src={profile.img ?? "https://via.placeholder.com/100"}
              alt="Doctor"
              className="w-20 h-20 rounded-full"
            />
            <div className="flex flex-col sm:text-center md:text-left">
              <h2 className="text-lg font-semibold">{profile.fullName}</h2>
              {profile.expericenceStart && (
                <p className="text-base mb-0 text-gray-600">
                  <span className="font-semibold">Experience:</span>{" "}
                  {calculateExperience(profile.expericenceStart)} years
                </p>
              )}
              <div className="text-base text-gray-600">
                <p className="font-semibold mb-2">Specializations:</p>
                {profile.specialistsList.map((item) => (
                  <p key={item.specialist.id} className="m-0 text-sm">
                    {item.specialist.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
          {/* Choose Hospital */}
          <div className="w-full flex flex-col items-center sm:items-start">
            <h3 className="text-lg font-semibold mb-1">Choose Hospital</h3>
            <select
              value={selectedHospital?.id ?? ""}
              onChange={handleHospitalChange}
              placeholder="Select Hospital"
              className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            >
              <option value="">Select Hospital</option>
              {hospitals.map((hospital, index) => (
                <option key={index} value={hospital.id}>
                  {hospital.name}
                </option>
              ))}
            </select>
            {selectedHospital && (
              <div className="mt-2 sm:text-center md:text-left [&>p]:m-0">
                <div className="text-blue-600 hover:underline flex items-center justify-center md:justify-start gap-2">
                  {selectedHospital.name}
                  <HospitalMapLink hospital={selectedHospital} />
                </div>

                <p className="text-base text-gray-600">
                  {selectedHospital.address}
                </p>
                <p>{selectedHospital.phone}</p>
              </div>
            )}
          </div>
        </div>
        {/* Doctor's Availability */}
        <div className="sm:col-span-7">
          <h3 className="text-lg font-semibold mb-1 text-center sm:text-left">
            Next Available Dates
          </h3>
          <div className="flex flex-col gap-2 justify-around overflow-y-hidden ">
            {availability.map((slot, index) => (
              <div key={index}>
                <p className="mb-1 font-semibold text-gray-500">{slot.date}</p>
                <div className="flex gap-2 justify-center sm:justify-start max-h-[20rem] overscroll-contain lg:hover:overflow-x-scroll  hover:overflow-x-scroll overflow-x-auto  gutter-stable  p-2">
                  {slot.timeSlots.map((time) => (
                    <Tooltip
                      key={time.slot.id}
                      title={time.slot.isSlotFull ? "Max Patient Reached" : ""}
                    >
                      <Button
                        shape="round"
                        onClick={() =>
                          handleSlotClick(slot.date, time.slot.time)
                        }
                        disabled={time.slot.isSlotFull}
                      >
                        {time.slot.time}
                      </Button>
                    </Tooltip>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="text-center sm:text-left">
            <Link to={`/booking/${profile.id}`}>
              <Button shape="round" className="mt-2 py-1 bg-brand text-white">
                View more
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const QuickAppointment = () => {
  const { data, isLoading } = useGetQuickAppointmentQuery({
    day: moment().format("YYYY-MM-DD"),
    limit: 2,
  });

  if (isLoading) {
    return (
      <div className="container flex items-center justify-center md:min-h-[40rem] p-4">
        <Spin size="large" />
      </div>
    );
  }

  const doctors = data ?? [];

  return (
    <div className="container mx-auto mt-20 p-4">
      <div className="w-full">
        <div className="md:flex">
          <h3 className="text-lg font-semibold mb-4">Quick Appointment</h3>
          <div className="flex-1">
            <SearchBar />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {doctors.map((item, index) => (
            <DoctorCard profile={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

const DirectAppointment = () => {
  return (
    <>
      <Header />
      <div className="md:min-h-screen">
        <QuickAppointment />
      </div>
      <Footer />
    </>
  );
};

export default DirectAppointment;
