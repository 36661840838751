import { message } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyDoctorEmailQuery } from "../../../redux/api/authApi";

const DoctorEmailVerify = () => {
  const { id, uniqueString } = useParams();

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login", { replace: true });
  };

  const { data, isLoading, isError, error } = useVerifyDoctorEmailQuery({
    userId: id,
    uniqueString,
  });

  useEffect(() => {
    if (isError) {
      message.error(error?.data?.message || "Something went wrong");
      navigateToLogin();
    }
  }, [data, navigate, isError]);

  if (isLoading)
    return (
      <>
        <h1>Verifying your email</h1>
      </>
    );

  if (!data) return null;

  const { verified, expired } = data;

  return (
    <div style={{ margin: "0px auto", maxWidth: "600px" }}>
      <table
        align="center"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        role="presentation"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td
              style={{
                direction: "ltr",
                fontSize: "0px",
                padding: "20px 0",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontSize: "0px",
                  textAlign: "left",
                  direction: "ltr",
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "100%",
                }}
              >
                <table
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  role="presentation"
                  style={{ verticalAlign: "top", width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td
                        align="left"
                        style={{
                          fontSize: "0px",
                          padding: "10px 25px",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            fontFamily:
                              "Montserrat, Helvetica, Arial, sans-serif",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "left",
                            color: "#dddddd",
                          }}
                        >
                          <h1
                            style={{
                              margin: 0,
                              fontSize: "46px",
                              lineHeight: "60px",
                              fontWeight: 600,
                              fontFamily:
                                "'Inknut Antiqua', Helvetica, Arial, sans-serif",
                            }}
                          >
                            {verified && "Your Email Verified Successfully !!"}
                            {expired && "Your Email token has expired !!"}
                          </h1>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          fontSize: "0px",
                          padding: "10px 25px",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          style={{
                            fontFamily:
                              "Montserrat, Helvetica, Arial, sans-serif",
                            fontSize: "18px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "left",
                            color: "#dddddd",
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            {verified && " Your Account Successfully Verified"}
                            {expired && (
                              <p>
                                A new verification link has been generated and
                                sent to your email. Please check your inbox.
                              </p>
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          fontSize: "0px",
                          padding: "10px 25px",
                          wordBreak: "break-word",
                        }}
                      >
                        {verified && (
                          <div
                            style={{
                              fontFamily:
                                "Montserrat, Helvetica, Arial, sans-serif",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "24px",
                              textAlign: "left",
                              color: "#999999",
                            }}
                          >
                            <p style={{ margin: 0 }}>Click here, to login in</p>

                            <button
                              className="iBtn"
                              type="submit"
                              value="sign In"
                              onClick={navigateToLogin}
                            >
                              Sign in
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DoctorEmailVerify;
