import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useResendCodeMutation,
  useVerifyCodeMutation,
} from "../../redux/api/authApi";
import { setUserInfo } from "../../utils/local-storage";

const encodePhoneNumber = (phoneNumber) => {
  const areaCode = phoneNumber.slice(0, 3);
  const prefix = phoneNumber.slice(3, 6);
  const suffix = phoneNumber.slice(6);
  return `${areaCode}-${prefix}-${suffix}`;
};

const OTPInput = ({ setValue, control, errors }) => {
  const inputsRef = useRef([]);

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");
    if (/^\d{6}$/.test(pasteData)) {
      const newOtp = pasteData.split("");

      newOtp.forEach((data, index) => {
        setValue(`otp[${index}]`, data);
      });

      // Autofocus the last input
      inputsRef.current[5].focus();
    }
  };

  return (
    <div className="flex gap-2">
      {Array.from({ length: 6 }).map((_, index) => (
        <Controller
          key={index}
          name={`otp[${index}]`} // Name each input individually
          control={control}
          defaultValue=""
          rules={{
            required: "This field is required",
            pattern: {
              value: /^\d$/,
              message: "Only digits are allowed",
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div>
              <input
                ref={(el) => (inputsRef.current[index] = el)}
                type="text"
                value={value}
                maxLength={1}
                autoFocus={index === 0}
                onChange={(e) => {
                  onChange(e.target.value); // Update the input value
                  if (/^\d$/.test(e.target.value) && index < 5) {
                    inputsRef.current[index + 1]?.focus(); // Focus next input
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && index > 0 && value === "") {
                    inputsRef.current[index - 1]?.focus(); // Focus previous input
                  }
                }}
                onPaste={handlePaste}
                className={`active:border-brand focus:border-brand outline-none text-lg focus-visible:border-brand w-12 h-12 border-2 rounded-md p-2 text-center ${
                  error ? "border-red-500" : value ? "border-green-500" : ""
                }`}
              />
            </div>
          )}
        />
      ))}
    </div>
  );
};

const VerifyOTP = () => {
  const pathname = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [encodePhone, setEncodePhone] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    setFocus,
    formState: { errors },
  } = useForm();

  const [resendCode] = useResendCodeMutation({
    id: pathname.id,
  });

  const [mutate, { isLoading }] = useVerifyCodeMutation();

  const handleResendCode = () => {
    resendCode({ id: pathname.id })
      .unwrap()
      .then((res) => {
        reset();
        setFocus("otp[0]");
        message.success(res.message);
      })
      .catch((error) => {
        message.error(error?.data?.message || "Something went wrong");
      });
  };

  const onSubmit = (data) => {
    const otpArray = data.otp || [];
    const otpCode = otpArray.join("");

    const state = location.state || {};

    mutate({
      id: pathname.id,
      code: otpCode,
      firstName: state?.patientInfo?.firstName,
      lastName: state?.patientInfo?.lastName,
      email: state?.patientInfo?.email,
    })
      .unwrap()
      .then((res) => {
        message.success("Successfully Logged in");
        navigate(redirectURL ?? "/", { state });
        setUserInfo({ accessToken: res.accessToken });
      })
      .catch((error) => {
        reset();
        message.error(error?.data?.message || "Something went wrong");
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get("redirect");
    setRedirectURL(redirectUrl);

    const state = location.state || {};

    setEncodePhone(state?.encodePhone);
  }, [location]);

  return (
    <form className="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="title">OTP Verification</h2>
      <p className="text-sm">
        Enter OTP code sent to your phone {encodePhone}.
      </p>

      <div className="my-4">
        <OTPInput setValue={setValue} control={control} errors={errors} />
      </div>

      <div className="my-3 text-center">
        <p className="mb-1">Didn't receive the code?</p>
        <button
          onClick={handleResendCode}
          type="button"
          className="m-0 text-brand"
        >
          Resend Code
        </button>
      </div>

      <button className="iBtn" type="submit">
        {isLoading ? "Verifying..." : "Verify"}
      </button>
    </form>
  );
};

export default VerifyOTP;
