import {
  Button,
  Collapse,
  DatePicker,
  Radio,
  Space,
  Spin,
  Tooltip,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useGetQuickAppointmentQuery } from "../../redux/api/doctorApi";
import HospitalMapLink from "../common/HospitalMapLink";

import React from "react";

const NextAvailableDates = ({
  selectedHospital,
  selectTime,
  handleSelectTime,
  date,
  setDateChange,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(selectTime);
  const { doctorId } = useParams();

  const { data, isLoading, isError, isFetching } = useGetQuickAppointmentQuery({
    day: moment().format("YYYY-MM-DD"),
    doctorId,
  });

  useEffect(() => {
    setSelectedTime(selectTime);
    setSelectedDate(date);
  }, [selectTime, date]);

  if (isLoading || isFetching) {
    return (
      <div className="flex justify-center items-center md:h-[20rem]">
        <Spin size="large" />
      </div>
    );
  }
  if (!selectedHospital) {
    return (
      <div className="flex justify-center items-center md:h-[20rem]">
        <h3>Please Select a Hospital</h3>
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div>{isError ? "Something Went Wrong!" : "No Data Available!"}</div>
    );
  }

  const hospital = data[0]?.hospitals?.find((h) => h.id === selectedHospital);
  const availableDates = hospital?.nextAvailableDates || [];

  const handleTimeSelection = (date, time) => {
    setSelectedDate(date);
    setSelectedTime(time);
    setDateChange(date);
    handleSelectTime(time);
  };

  const isSelectedSlot = (date, time) =>
    selectedDate === date && selectedTime === time;

  return (
    <div className="col-span-8">
      <h5 className="text-title font-semibold mb-1">
        Upcoming Available Dates
      </h5>
      <p className="text-gray-500 text-sm">
        Here are the next 5 available dates for the doctor:
      </p>
      <div className="border shadow-sm rounded p-2 w-full">
        {availableDates.length === 0 ? (
          <p className="mb-1 font-semibold text-gray-500">No Available Dates</p>
        ) : (
          <div className="flex justify-around">
            {availableDates.map((slot, index) => (
              <div key={index} className="overflow-hidden">
                <p className="mb-2 font-semibold text-brand">{slot.date}</p>
                <div className="flex flex-col gap-3 max-h-[20rem] gutter-stable overscroll-contain hover:overflow-y-scroll p-2">
                  {slot.timeSlots.map(({ slot: timeSlot }) => (
                    <Tooltip
                      key={timeSlot.id}
                      title={timeSlot.isSlotFull ? "Max Patient Reached" : ""}
                    >
                      <Button
                        type={
                          isSelectedSlot(slot.date, timeSlot.time)
                            ? "primary"
                            : "default"
                        }
                        shape="round"
                        size="large"
                        onClick={() =>
                          handleTimeSelection(slot.date, timeSlot.time)
                        }
                        disabled={timeSlot.isSlotFull}
                      >
                        {timeSlot.time}
                      </Button>
                    </Tooltip>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const AdvancedAppointmentBooking = ({
  handleDateChange,
  disabledDateTime,
  selectedDate,
  dContent,
  selectTime,
}) => {
  return (
    <div className="row flex-column p-4">
      <div>
        <h5 className="text-title mb-3">Please Select Date</h5>
        <DatePicker
          format="YYYY-MM-DD"
          className="w-100"
          disabledDate={disabledDateTime}
          onChange={handleDateChange}
          // defaultValue={moment().add(1, 'days')}
        />
      </div>
      <div className=" mt-3">
        {selectedDate && (
          <h5 className="text-title mb-3">
            Selected Date: {selectedDate && moment(selectedDate).format("LL")}
            {selectTime && "Time: " + selectTime}
          </h5>
        )}
        <div className="date-card rounded">
          <div className="row text-center mt-3">
            {!selectedDate ? (
              <h5 className="text-title d-flex justify-content-center align-items-center mt-5">
                Please Select A Date First
              </h5>
            ) : (
              dContent
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectDateAndTime = ({
  content,
  details,
  date,
  handleDateChange,
  disabledDateTime,
  selectedDate,
  dContent,
  selectTime,
  handleHospitalChange,
  handleSelectTime,
  setDateChange,
}) => {
  const [advancedOption, setAdvancedOption] = useState({ activeKey: null });
  const [hospitalSelecting, setHospitalSelecting] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState("");

  // Effect to set the default hospital with the least distance
  useEffect(() => {
    console.log(details?.hospitalDoctors);
    if (details?.hospitalDoctors?.length > 0 && !selectedHospital) {
      setHospitalSelecting(true);
      const closestHospital = details.hospitalDoctors.reduce((prev, curr) => {
        return prev.hospital.distance < curr.hospital.distance ? prev : curr;
      });

      setSelectedHospital(closestHospital.hospital.id);
      handleHospitalChange(closestHospital.hospital.id);
      setHospitalSelecting(false);
    }
  }, [details, handleHospitalChange]);

  return (
    <div className="flex flex-col md:flex-row items-start gap-5">
      <div className="flex flex-column  gap-2 ">
        <div className="">
          <h5 className="text-title">Selected Doctor</h5>
          {content}
        </div>
        <div className="md:w-[25rem]">
          <h5 className="text-title mb-3 fw-bold">Select Hospital</h5>
          <Radio.Group
            value={selectedHospital}
            buttonStyle="outline"
            onChange={(event) => {
              setSelectedHospital(event.target.value);
              handleHospitalChange(event.target.value);
            }}
          >
            <Space direction="vertical">
              {details?.hospitalDoctors?.map(({ hospital }) => (
                <Radio key={hospital?.id} value={hospital?.id}>
                  <div className="flex gap-2 items-center">
                    {" "}
                    {hospital?.name}
                    <HospitalMapLink hospital={hospital} />
                  </div>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          {/* <select
            id="doctorSelect"
            className="form-control "
            value={selectedHospital}
            onChange={(event) => {
              setSelectedHospital(event.target.value);
              handleHospitalChange(event.target.value);
            }}
          >
            <option value="">Select a Hospital</option>
            {details?.hospitalDoctors?.map(({ hospital }) => (
              <option key={hospital?.id} value={hospital?.id}>
                {hospital?.name}
              </option>
            ))}
          </select> */}
        </div>
      </div>

      <div className="row flex-1">
        {/* Doctor's Availability */}
        {advancedOption.activeKey === null && (
          <NextAvailableDates
            selectedHospital={selectedHospital}
            selectTime={selectTime}
            date={date}
            setDateChange={setDateChange}
            handleSelectTime={handleSelectTime}
          />
        )}

        <Collapse
          collapsible="header"
          ghost
          activeKey={advancedOption.activeKey}
          onChange={(e) => {
            const value = e?.[0] ?? null;

            setAdvancedOption({ activeKey: value });
            handleSelectTime(null);
            handleDateChange(null);
          }}
          items={[
            {
              key: "1",
              label: (
                <div className="flex items-center mx-auto justify-center gap-2  border rounded-lg p-2 w-fit">
                  <FaRegEye />
                  <span>
                    {advancedOption.activeKey
                      ? "View basic options"
                      : "View advanced options"}
                  </span>
                </div>
              ),
              showArrow: false,
              children: (
                <AdvancedAppointmentBooking
                  handleDateChange={handleDateChange}
                  disabledDateTime={disabledDateTime}
                  selectedDate={selectedDate}
                  dContent={dContent}
                  selectTime={selectTime}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SelectDateAndTime;
