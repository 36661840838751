import { Button, message } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetBlogCategoryQuery,
  useUpdateBlogCategoryMutation,
} from "../../../redux/api/blogApi";
import AdminLayout from "../AdminLayout/AdminLayout";

import { FaAngleLeft } from "react-icons/fa";

const EditBlogCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const { data, isLoading } = useGetBlogCategoryQuery(id);

  const [mutate, { isLoading: updateLoading }] =
    useUpdateBlogCategoryMutation();

  const onSubmit = (data) => {
    mutate({ data, id })
      .unwrap()
      .then((res) => {
        if (res.id) {
          reset();
          message.success("Successfully Updated !!");
          navigate("/admin/blog-categories");
        } else throw new Error("Something went wrong");
      })
      .catch((error) => {
        console.log(error);
        message.error(error?.data?.message || "Something went wrong");
      });
  };

  useEffect(() => {
    if (!isLoading && data) {
      reset(data);
    }
  }, [data, isLoading]);

  return (
    <AdminLayout>
      <div className="flex items-center gap-3 mb-3">
        <Link
          to="/admin/blog-categories"
          className="getbackButton hover:text-white hover:bg-brand/90"
        >
          {" "}
          <FaAngleLeft className="inline" /> Go Back
        </Link>
        <h3 className="m-0">Edit Blog Category</h3>
      </div>
      <div className="card mb-5 p-2 shadow-sm">
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Name</label>
              <input
                placeholder="Enter category name"
                {...register("name", { required: true })}
                className="form-control"
              />
              {errors.title?.type === "required" && (
                <span className="text-danger">Category name is required</span>
              )}
            </div>
          </div>

          <div className="text-center my-3">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={updateLoading}
              disabled={updateLoading ? true : false}
            >
              {updateLoading ? "Saving ..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default EditBlogCategory;
