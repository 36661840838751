import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
} from "@mui/material";
import { message, Spin } from "antd";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  useCreateTimeSlotMutation,
  useDeleteTimeSlotMutation,
  useGetDoctorTimeSlotQuery,
} from "../../../redux/api/timeSlotApi";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import setHosptialColor from "../../../utils/hospitalColor";
import AddDatePickerEventModal from "./AddDatePickerEventModal";
import AddEventModal from "./AddEventModal";
import EventInfo from "./EventInfo";
import EventInfoModal from "./EventInfoModal";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const generateId = () =>
  (Math.floor(Math.random() * 10000) + 1).toString();

const initialEventFormState = {
  description: "",
  maximumPatient: 1,
};

const initialDatePickerEventFormData = {
  description: "",
  maximumPatient: 1,
  start: undefined,
  end: undefined,
  repeatEvent: {
    repeatType: null,
    repeatFrequency: null,
    repeatUntil: null,
    weekDays: [],
  },
  hospitalId: null,
};

const EventCalendar = () => {
  const [openSlot, setOpenSlot] = useState(false);
  const [openDatepickerModal, setOpenDatepickerModal] = useState(false);
  const [eventInfoModal, setEventInfoModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const [eventFormData, setEventFormData] = useState(initialEventFormState);
  const [datePickerEventFormData, setDatePickerEventFormData] = useState(
    initialDatePickerEventFormData
  );

  const { data, isLoading, isError } = useGetDoctorTimeSlotQuery();
  const [createTimeSlot] = useCreateTimeSlotMutation();
  const [deleteTimeSlot] = useDeleteTimeSlotMutation();
  const [currentView, setCurrentView] = useState(Views.MONTH);

  const [hospitals, setHospitals] = useState([]);
  const [slotDuration, setSlotDuration] = useState(null);
  const auth = useAuthCheck();

  useEffect(() => {
    if (auth?.data?.hospitalDoctors) {
      const hospitalWithColor = setHosptialColor(auth?.data?.hospitalDoctors);
      setHospitals(hospitalWithColor);
      setSlotDuration(auth?.data?.slotDuration);
    }
  }, [auth.data]);

  useEffect(() => {
    if (data) {
      const mappedEvents = data
        .map((slot) =>
          slot.timeSlot.map((time) => ({
            id: time.id,
            description: time.description || "No Description",
            start: new Date(time.startTime),
            end: new Date(time.endTime),
            doctorId: slot.doctorId,
            maximumPatient: time.maximumPatient,
            isRepeatSchedule: time.isRepeatSchedule,
            doctorTimeSlotId: time.doctorTimeSlotId,
            hospitalId: slot.hospitalId,
            hospital: slot.hospital,
          }))
        )
        .flat(); // Flatten the array of arrays into a single array

      setEvents(mappedEvents);
      setFilterEvents(mappedEvents);
    }
  }, [data]);

  const handleSelectSlot = (event) => {
    if (currentView !== Views.MONTH && event.box) {
      setOpenSlot(true);
      setCurrentEvent(event);
    }
  };

  const handleSelectEvent = (event) => {
    console.log(event);
    setCurrentEvent(event);
    setEventInfoModal(true);
  };

  const handleClose = () => {
    setEventFormData(initialEventFormState);
    setOpenSlot(false);
  };

  const handleDatePickerClose = () => {
    setDatePickerEventFormData(initialDatePickerEventFormData);
    setOpenDatepickerModal(false);
  };

  const onAddEvent = async (e) => {
    e.preventDefault();

    if (currentEvent.start === currentEvent.end) {
      message.error("Start and end times cannot be the same.");
      return;
    }

    const data = {
      day: format(new Date(currentEvent?.start), "yyyy-MM-dd"), // Use local date format
      timeSlot: [
        {
          startTime: format(
            new Date(currentEvent?.start),
            "yyyy-MM-dd'T'HH:mm:ss"
          ), // Use local date format
          endTime: format(new Date(currentEvent?.end), "yyyy-MM-dd'T'HH:mm:ss"), // Use local date format
          maximumPatient: eventFormData.maximumPatient,
          description: eventFormData.description,
        },
      ],
      hospitalId: datePickerEventFormData?.hospitalId,
      repeatEvent: {
        ...datePickerEventFormData.repeatEvent,
        repeatFrequency: +datePickerEventFormData.repeatFrequency ?? 1,
        repeatUntil: datePickerEventFormData?.repeatEvent?.repeatUntil
          ? format(
              new Date(
                datePickerEventFormData?.repeatEvent?.repeatUntil?.toString()
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
          : null,
      },
    };

    try {
      await createTimeSlot({ data });
      const newEvents = [
        ...events,
        {
          ...data,
          _id: generateId(),
          start: new Date(currentEvent?.start),
          end: new Date(currentEvent?.end),
        },
      ];
      setEvents(newEvents);
      handleClose();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  const onAddEventFromDatePicker = async (e) => {
    e?.preventDefault();

    const setMinToZero = (date) => {
      date?.setSeconds(0);
      return date;
    };
    const data = {
      day: format(new Date(datePickerEventFormData.start), "yyyy-MM-dd"), // Use local date format
      timeSlot: [
        {
          startTime: format(
            setMinToZero(new Date(datePickerEventFormData.start)),
            "yyyy-MM-dd'T'HH:mm:ss"
          ), // Use local date format
          endTime: format(
            setMinToZero(new Date(datePickerEventFormData.end)),
            "yyyy-MM-dd'T'HH:mm:ss"
          ), // Use local date format
          description: datePickerEventFormData.description,
          maximumPatient: datePickerEventFormData.maximumPatient,
        },
      ],
      hospitalId: datePickerEventFormData?.hospitalId,
      repeatEvent: {
        ...datePickerEventFormData.repeatEvent,
        repeatFrequency: +datePickerEventFormData.repeatFrequency ?? 1,
        repeatUntil: datePickerEventFormData?.repeatEvent?.repeatUntil
          ? format(
              new Date(
                datePickerEventFormData?.repeatEvent?.repeatUntil?.toString()
              ),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
          : null,
      },
    };

    try {
      await createTimeSlot({ data });
      const newEvents = [
        ...events,
        {
          ...data,
          _id: generateId(),
          start: new Date(datePickerEventFormData.start),
          end: new Date(datePickerEventFormData.end),
        },
      ];
      setEvents(newEvents);
      setDatePickerEventFormData(initialDatePickerEventFormData);
    } catch (error) {
      console.error("Error adding event from date picker:", error);
    }
  };

  const onDeleteEvent = async (deleteAll) => {
    console.log(deleteAll);
    try {
      console.log("delete", currentEvent);
      const res = await deleteTimeSlot({ id: currentEvent.id, deleteAll });
      if (res.error) {
        message.error(res.error.data.message);
      } else {
        let filterEvents = [];
        if (deleteAll) {
          filterEvents = events.filter(
            (e) => e.doctorTimeSlotId !== currentEvent?.doctorTimeSlotId
          );
        } else {
          filterEvents = events.filter((e) => e.id !== currentEvent?.id);
        }
        setEvents(filterEvents);
        setFilterEvents(filterEvents);

        message.success("TimeSlot Successfully Deleted !!");
      }
    } catch (error) {
      message.error(error.message);
    }
    setEventInfoModal(false);
  };

  const handleHospitalChange = (hospitalId) => {
    if (hospitalId === "") {
      setFilterEvents([...events]);
      return;
    }
    const filterEvents = events.filter(
      (event) => event.hospitalId === hospitalId
    );
    setFilterEvents(filterEvents);
  };

  const setDynamicHospitalColor = (event) => {
    const color = event?.hospitalId
      ? hospitals.find((item) => item?.hospital.id === event?.hospitalId)?.color
      : null;
    return color;
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );

  return (
    <Box mb={2} component="main" sx={{ flexGrow: 1 }}>
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Calendar"
            subheader="Create Events and Todos and manage them easily"
          />
          <Divider />
          <CardContent>
            <div className="flex justify-between">
              <Button
                onClick={() => setOpenDatepickerModal(true)}
                size="small"
                variant="contained"
              >
                Add schedule
              </Button>

              <div className="flex items-center gap-2">
                <label>View By:</label>
                <select
                  className="select border p-1 rounded-sm"
                  name="hospitals"
                  onChange={(e) => handleHospitalChange(e.target.value)}
                >
                  <option value={""}>All Hospitals</option>
                  {hospitals.map((item) => {
                    return (
                      <option value={item?.hospital.id} key={item?.hospital.id}>
                        {item?.hospital.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="hospital-display flex gap-2 justify-end my-2">
              {hospitals.map((item) => {
                return (
                  <div
                    key={item?.hospital.id}
                    className="hospital-item flex items-center gap-2"
                  >
                    <div
                      className="w-4 h-4 rounded-full"
                      style={{ backgroundColor: item?.color }}
                    ></div>
                    <span>{item?.hospital.name}</span>
                  </div>
                );
              })}
            </div>
            <Divider style={{ margin: 10 }} />
            <AddEventModal
              open={openSlot}
              handleClose={handleClose}
              eventFormData={eventFormData}
              setEventFormData={setEventFormData}
              onAddEvent={onAddEvent}
            />
            <AddDatePickerEventModal
              open={openDatepickerModal}
              handleClose={handleDatePickerClose}
              datePickerEventFormData={datePickerEventFormData}
              setDatePickerEventFormData={setDatePickerEventFormData}
              onAddEvent={onAddEventFromDatePicker}
              hospitals={hospitals}
              slotDuration={slotDuration}
            />
            <EventInfoModal
              open={eventInfoModal}
              handleClose={() => setEventInfoModal(false)}
              onDeleteEvent={onDeleteEvent}
              currentEvent={currentEvent}
            />
            <Calendar
              localizer={localizer}
              events={filterEvents}
              step={60}
              timeslots={1}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={(slotInfo) => {
                handleSelectSlot(slotInfo);
              }}
              selectable
              tooltipAccessor={(event) => event?.hospital?.name}
              views={["month", "week", "day"]} // Include the month view but make it read-only
              startAccessor="start"
              components={{ event: EventInfo }}
              endAccessor="end"
              defaultView={Views.MONTH} // Default view is month
              eventPropGetter={(event) => {
                const color = setDynamicHospitalColor(event);
                return {
                  style: {
                    backgroundColor: color,
                    borderColor: color,
                  },
                };
              }}
              style={{ height: 600 }}
              onView={setCurrentView}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default EventCalendar;
