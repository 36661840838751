import React from "react";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import Blog from "../Blog/Blog";
import BookDoctor from "../BookOurDoctor/BookDoctor";
import ClinicAndSpecialities from "../ClinicAndSpecialities/ClinicAndSpecialities";
import Gallery from "../Gallery/Gallery";
import HeroSection from "../HeroSection/HeroSection";
import InfoPage from "../InfoPage/InfoPage";
import OurDoctors from "../OurDoctor/OurDoctors";
import Service from "../Services/Service";
import Testimonial from "../Testimonial/Testimonial";

const Home = () => {
  return (
    <>
      <Header />
      <div className="mt-[5rem]">
        <HeroSection />
        <BookDoctor />
        <InfoPage />
        <Service />
        <ClinicAndSpecialities />
        {/* <Availabe /> */}
        <OurDoctors />
        <Blog />
        <Testimonial />
        <Gallery />
      </div>
      <Footer />
    </>
  );
};

export default Home;
