import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment/moment";

// interface IProps {
//   open: boolean
//   handleClose: Dispatch<SetStateAction<void>>
//   onDeleteEvent: (e: MouseEvent<HTMLButtonElement>) => void
//   currentEvent: IEventInfo | null
// }

const EventInfoModal = ({ open, handleClose, onDeleteEvent, currentEvent }) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Event Info</DialogTitle>
      <DialogContent className="md:min-w-[25rem]">
        <DialogContentText>
          <Typography sx={{ fontSize: 14, marginTop: 3 }} gutterBottom>
            <span className="font-bold text-black">Start Date:</span>{" "}
            {moment(currentEvent?.start.toString()).format("LLL")}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography sx={{ fontSize: 14, marginTop: 3 }} gutterBottom>
            <span className="font-bold text-black">End Date:</span>{" "}
            {moment(currentEvent?.end.toString()).format("LLL")}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography sx={{ fontSize: 14, marginTop: 3 }} gutterBottom>
            <span className="font-bold text-black">Hospital:</span>{" "}
            {currentEvent?.hospital?.name}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography sx={{ fontSize: 14, marginTop: 3 }} gutterBottom>
            <span className="font-bold text-black">Description:</span>{" "}
            {currentEvent?.description}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography sx={{ fontSize: 14, marginTop: 3 }} gutterBottom>
            <span className="font-bold text-black">Maximum Patient:</span>{" "}
            {currentEvent?.maximumPatient}
          </Typography>
        </DialogContentText>
        <Box component="form"></Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
        {currentEvent?.isRepeatSchedule ? (
          <>
            <Button color="warning" onClick={() => onDeleteEvent(true)}>
              Delete all occurrences
            </Button>
            <Button color="info" onClick={() => onDeleteEvent(false)}>
              Delete only this event
            </Button>
          </>
        ) : (
          <Button color="info" onClick={() => onDeleteEvent(false)}>
            Delete Event
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EventInfoModal;
