import React from "react";
import { FaBriefcase } from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const OverView = ({ data }) => {

  const showEducationTimeLine = data?.educations?.length > 0;

  const showWorkTimeLine = data?.experiences?.length > 0;

  const showAwardsTimeLine = data?.awards?.length > 0;

  return (
    <div className="col-md-12 col-lg-9">
      <div className="mb-3">
        <h5 className="overview-text">About Me</h5>
        <p className="text-secondary">{data?.biography}</p>
      </div>
      <div>
        <h5 className="overview-text">Education</h5>
        {showEducationTimeLine ? (
          data.educations.map((edu) => (
            <VerticalTimeline key={edu.id}>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#2e81c4", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #2e81c4" }}
                date={edu?.completionYear}
                iconStyle={{ background: "#2e81c4", color: "#fff" }}
                icon={<FaBriefcase />}
              >
                <h5 className="text-white">College : {edu?.institution}</h5>
                <h5 className="text-white">Degree : {edu?.degree}</h5>
              </VerticalTimelineElement>
            </VerticalTimeline>
          ))
        ) : (
          <p>Educational timeline information is currently unavailable.</p>
        )}
      </div>
      <div>
        <h5 className="overview-text">Work & Experience</h5>

        {showWorkTimeLine ? (
          data.experiences.map((exp) => (
            <VerticalTimeline key={exp.id}>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#2e81c4", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #2e81c4" }}
                date={`${exp?.startDate} - ${
                  exp?.endDate?.length ? exp?.endDate : "Present"
                }`}
                iconStyle={{ background: "#2e81c4", color: "#fff" }}
                icon={<FaBriefcase />}
              >
                <p className="text-white my-1">
                  Hospital : {exp?.hospitalName}
                </p>
                <p className="text-white my-1">
                  Designation : {exp?.designation}
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          ))
        ) : (
          <p>Work timeline information is currently unavailable.</p>
        )}
      </div>
      <div>
        <h5 className="overview-text">Awards</h5>

        {showAwardsTimeLine ? (
          data.awards.map((award) => (
            <VerticalTimeline key={award.id}>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#2e81c4", color: "#000" }}
                contentArrowStyle={{ borderRight: "7px solid  #2e81c4" }}
                date={award?.year}
                iconStyle={{ background: "#2e81c4", color: "#fff" }}
                icon={<FaBriefcase />}
              >
                <h5 className="text-white">{award?.title}</h5>
              </VerticalTimelineElement>
            </VerticalTimeline>
          ))
        ) : (
          <p>Awards timeline information is currently unavailable.</p>
        )}
      </div>
      <div>
        <h5 className="overview-text">Services</h5>
        {data?.servicesList.length === 0 && <p>No Services</p>}
        <ul>
          {data &&
            data.servicesList.map(({ service }) => {
              return <li key={service.id}>{service.name}</li>;
            })}
        </ul>
      </div>
      <div>
        <h5 className="overview-text">Specializations</h5>
        {data?.specialistsList === 0 && <p>No Specializations</p>}
        <ul className="clearfix">
          {data &&
            data.specialistsList.map(({ specialist }) => {
              return <li key={specialist.id}>{specialist.name}</li>;
            })}
        </ul>
      </div>
    </div>
  );
};
export default OverView;
