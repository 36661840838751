import { Button, Radio, Slider } from "antd";
import Search from "antd/es/input/Search";
import React, { useContext, useEffect, useState } from "react";
import { FaRedoAlt, FaSearch } from "react-icons/fa";
import { SettingContext } from "../../context/setting";
import "./index.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useGetHospitalDDLQuery } from "../../../redux/api/hospital";
import { useGetAllServicesQuery } from "../../../redux/api/servicesApi";
import { useGetAllSpecialistQuery } from "../../../redux/api/specialites";

const SearchSidebar = ({
  searchTerm,
  setSearchTerm,
  setSorByGender,
  setSpecialist,
  setPriceRange,
  setHospital,
  setService,
  resetFilter,
  query,
}) => {
  const { data: specialList } = useGetAllSpecialistQuery();
  const { data: servicesList } = useGetAllServicesQuery();
  const { data: hospitalList } = useGetHospitalDDLQuery();

  const setting = useContext(SettingContext);
  const [ShowPrice, setShowPrice] = useState(setting[0].isDisplayPrice);
  const [touched, setTouched] = useState(true);
  const [priceRangeState, setPriceRangeState] = useState([75, 100]);

  // Extract query parameters
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const DName = queryParams.get("Dname");
  const HName = queryParams.get("Hname");
  const serviceId = queryParams.get("service");
  const SpecialId = queryParams.get("speciality");

  const handleReset = () => {
    // Reset all states and delete query parameters
    setTouched(true);
    resetFilter();
    setPriceRangeState([75, 100]);

    // Clear query parameters
    queryParams.delete("Dname");
    queryParams.delete("Hname");
    queryParams.delete("service");
    queryParams.delete("speciality");

    // Update the URL
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  // Effect to handle query param updates
  useEffect(() => {
    if (touched) {
      if (serviceId) setService(serviceId);
      if (HName) setHospital(HName);
      if (DName) setSearchTerm(DName);

      if (SpecialId) setSpecialist(SpecialId);

      setTouched(false); // Prevent running this effect repeatedly
    }
  }, [
    touched,
    serviceId,
    HName,
    DName,
    SpecialId,
    setSearchTerm,
    setService,
    setHospital,
    setSpecialist,
  ]);

  const options = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const handleGenderSelect = (e) => setSorByGender(e.target.value);
  const handleSpecialistSelect = (e) => setSpecialist(e.target.value);
  const handleServiceSelect = (e) => setService(e.target.value);
  const handleHospitalSelect = (e) => setHospital(e.target.value);

  const handlePriceRangeChangeComplete = (range) => {
    const priceRange = { min: range[0], max: range[1] };
    setPriceRange(priceRange);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  return (
    <div className="col-md-12 col-lg-4 col-xl-3">
      <div
        className="doctor-filter-card mt-4 rounded"
        style={{ boxShadow: "#DFDFE1 4px 12px 9px 2px", padding: "2rem" }}
      >
        <h5 className="text-center mb-3" style={{ color: "#05335c" }}>
          Doctor Filter
        </h5>

        {/* Search input */}
        <div className="mb-3">
          <Search
            placeholder="Search..."
            onSearch={handleSearchChange}
            onInput={(e) => setSearchTerm(e.target.value)}
            enterButton
            allowClear
            value={searchTerm ?? ""}
          />
        </div>

        {/* Gender selection */}
        <div className="mb-3">
          <h6 style={{ color: "#05335c" }}>Gender</h6>
          <Radio.Group
            options={options}
            value={query.gender || ""}
            onChange={handleGenderSelect}
          />
        </div>

        {/* Price range */}
        {ShowPrice && (
          <div className="mb-3">
            <h6 style={{ color: "#05335c" }}>Price Range</h6>
            <Slider
              range
              onChange={(value) => setPriceRangeState(value)}
              onChangeComplete={handlePriceRangeChangeComplete}
              value={priceRangeState}
            />
          </div>
        )}

        {/* Service selection */}
        <div className="mb-3">
          <label htmlFor="doctorSelect">Select Service</label>
          <select
            id="doctorSelect"
            className="form-control"
            onChange={handleServiceSelect}
            value={query.service || ""}
          >
            <option value="">Select a Service</option>
            {servicesList?.services?.map((service) => (
              <option key={service?.id} value={service?.id}>
                {service?.name}
              </option>
            ))}
          </select>
        </div>

        {/* Hospital selection */}
        <div className="mb-3">
          <label htmlFor="doctorSelect">Select Hospitals</label>
          <select
            id="doctorSelect"
            className="form-control"
            onChange={handleHospitalSelect}
            value={query.hospital || ""}
          >
            <option value="">Select a Hospital</option>
            {hospitalList?.map((hospital) => (
              <option key={hospital?.id} value={hospital?.id}>
                {hospital?.name}
              </option>
            ))}
          </select>
        </div>

        {/* Specialist selection */}
        <div className="mb-3">
          <h6 style={{ color: "#05335c" }}>Select Specialist</h6>
          <Radio.Group
            options={specialList?.specialist?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            onChange={handleSpecialistSelect}
            value={query.specialist || ""}
          />
        </div>

        {/* Search button */}
        <Button
          className="btn btn-primary search w-100 mt-4 mb-2"
          type="primary"
          style={{ backgroundColor: "var(--primary)",    display: "flex", 
            alignItems: "center", 
            justifyContent: "center",  }}
          shape="round"
          icon={<FaSearch style={{ verticalAlign: "middle" }} />}
          size="sm"
        >
          Search
        </Button>

        {/* Reset button */}
        {Object.keys(query).length > 4 && (
          <Button
            className="w-100 mt-4 mb-2"
            style={{ backgroundColor: "#1977cc" }}
            onClick={handleReset}
            type="primary"
            shape="round"
            icon={<FaRedoAlt />}
            size="sm"
          >
            Reset
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchSidebar;
