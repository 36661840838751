import { setUserInfo } from "../../utils/local-storage";
import { baseApi } from "./baseApi";

const AUTH_URL = "/auth";

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    userLogin: build.mutation({
      query: (loginData) => ({
        url: `${AUTH_URL}/login`,
        method: "POST",
        data: loginData,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = (await queryFulfilled).data;
          setUserInfo({ accessToken: result.accessToken });
        } catch (error) {}
      },
    }),
    generateCode: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/generate-otp`,
        method: "POST",
        data,
      }),
    }),
    resendCode: build.mutation({
      query: ({ id }) => ({
        url: `${AUTH_URL}/resend-otp/${id}`,
        method: "GET",
      }),
    }),
    verifyCode: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `${AUTH_URL}/verify-otp/${id}`,
        method: "POST",
        data: rest,
      }),
    }),
    AdminLogin: build.mutation({
      query: (loginData) => ({
        url: `${AUTH_URL}/admin/login`,
        method: "POST",
        data: loginData,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const result = (await queryFulfilled).data;

          setUserInfo({ accessToken: result.accessToken });
          return { isAdmin: true };
        } catch (error) {
          console.log(error);
        }
      },
    }),

    patientSignUp: build.mutation({
      query: (data) => ({
        url: `/patient`,
        method: "POST",
        data,
      }),
    }),
    doctorSignUp: build.mutation({
      query: (data) => ({
        url: `/doctor`,
        method: "POST",
        data,
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/reset-password`,
        method: "POST",
        data,
      }),
    }),
    resetConfirm: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/reset-password/confirm`,
        method: "POST",
        data,
      }),
    }),
    changePassword: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/change-password`,
        method: "POST",
        data,
      }),
    }),
    verifyDoctorEmail: build.query({
      query: ({ userId, uniqueString }) => ({
        url: `${AUTH_URL}/user/verify/${userId}/${uniqueString}`,
        method: "GET",
      }),
      providesTags: ["verfiyDoctorEmail"],
    }),
    appointmentResponse: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/appointment/response`,
        method: "POST",
        data,
      }),
    }),
    pendingAppointmentResponse: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/pending-appointment/response`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useAdminLoginMutation,
  useVerifyCodeMutation,
  useGenerateCodeMutation,
  useResendCodeMutation,
  useDoctorSignUpMutation,
  usePatientSignUpMutation,
  useResetPasswordMutation,
  useResetConfirmMutation,
  useChangePasswordMutation,
  useVerifyDoctorEmailQuery,
  useAppointmentResponseMutation,
  usePendingAppointmentResponseMutation,
} = authApi;
