import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";

const DOC_URL = "/doctor";

export const doctorApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDoctors: build.query({
      query: (arg) => ({
        url: `${DOC_URL}`,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          doctors: response.data,
          meta: response.meta,
        };
      },
      providesTags: [tagTypes.doctor],
    }),
    getDoctorsDDL: build.query({
      query: () => ({
        url: `${DOC_URL}/dropdown`,
        method: "GET",
      }),
      providesTags: [tagTypes.hospital],
    }),
    getDoctor: build.query({
      query: (id) => ({
        url: `${DOC_URL}/${id}`,
        method: "GET",
      }),
      providesTags: [tagTypes.doctor],
    }),
    getDoctorDashboard: build.query({
      query: (id) => ({
        url: `${DOC_URL}/dashboard/${id}`,
        method: "GET",
      }),
      providesTags: [tagTypes.doctor],
    }),
    updateDoctor: build.mutation({
      query: ({ data, id }) => ({
        url: `${DOC_URL}/${id}`,
        method: "PATCH",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [tagTypes.doctor],
    }),
    createDoctor: build.mutation({
      query: ({ data }) => ({
        url: `${DOC_URL}`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [tagTypes.doctor],
    }),
    deleteDoctor: build.mutation({
      query: (id) => ({
        url: `${DOC_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [tagTypes.blogs],
    }),
    invitePatient: build.mutation({
      query: ({ doctorId, data }) => ({
        url: `${DOC_URL}/invite-patient/${doctorId}`,
        method: "POST",
        data: data,
      }),
    }),
    getQuickAppointment: build.query({
      query: ({ day, doctorId, limit }) => ({
        url: `${DOC_URL}/quick-appointment`,
        params: { day, doctorId, limit },
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetDoctorsQuery,
  useGetDoctorsDDLQuery,
  useGetDoctorQuery,
  useGetDoctorDashboardQuery,
  useUpdateDoctorMutation,
  useCreateDoctorMutation,
  useDeleteDoctorMutation,
  useInvitePatientMutation,
  useGetQuickAppointmentQuery,
} = doctorApi;
