import { useEffect, useState } from "react";
import { getUserInfo } from "../../service/auth.service";
import { useGetDoctorQuery } from "../api/doctorApi";
import { useGetPatientQuery } from "../api/patientApi";

export default function useAuthCheck() {
  const [authChecked, setAuthChecked] = useState(false);
  const [userId, setUserId] = useState("");
  const [isSkip, setIsSkip] = useState(true);
  const [data, setData] = useState(null);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true); // New loading state

  const {
    data: doctorData,
    isError: dIsError,
    isSuccess: dIsSuccess,
  } = useGetDoctorQuery(userId, { skip: isSkip });
  const {
    data: patientData,
    isError: pIsError,
    isSuccess: pIsSuccess,
  } = useGetPatientQuery(userId, { skip: isSkip });

  useEffect(() => {
    const localAuth = getUserInfo();

    if (!localAuth) {
      setLoading(false);
      return;
    }

    if (localAuth && localAuth !== null) {
      setUserId(localAuth?.userId);
      setIsSkip(false);
      setRole(localAuth.role);

      if (localAuth.role === "patient") {
        setData(patientData);
        setAuthChecked(pIsSuccess && !pIsError);
      } else if (localAuth.role === "doctor") {
        setData(doctorData);
        setAuthChecked(dIsSuccess && !dIsError);
      }
    } 

    // Ensure loading is set to false after initial checks
    if (
      (localAuth?.role === "patient" && (pIsSuccess || pIsError)) ||
      (localAuth?.role === "doctor" && (dIsSuccess || dIsError))
    ) {
      setLoading(false);
    }
  }, [patientData, doctorData, dIsError, dIsSuccess, pIsError, pIsSuccess]);

  return {
    authChecked,
    data,
    role,
    loading, // Return loading state
  };
}
