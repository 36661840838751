import { Spin } from "antd";
import React from "react";
import { useGetTermAndConditionQuery } from "../../redux/api/settingApi";
import RenderHTML from "../../utils/RenderHtml";

const TermsAndCondition = () => {
  const { data, isLoading } = useGetTermAndConditionQuery();
  return (
    <div className=" p-4">
      <h1 className="text-2xl text-center">Terms and Conditions</h1>
      {isLoading ? (
        <Spin />
      ) : (
        <RenderHTML htmlString={data?.termsAndConditions} />
      )}
    </div>
  );
};

export default TermsAndCondition;
