import { Button, message } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import { useChangePasswordMutation } from "../../../redux/api/authApi";
import DashboardLayout from "../DashboardLayout/DashboardLayout";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
  });

  const [mutate] = useChangePasswordMutation();

  const onSubmit = (data) => {
    mutate(data)
      .unwrap()
      .then((res) => {
        reset();
        message.success(res.message);
      })
      .catch((error) => {
        message.error(error?.data?.message || "Something went wrong");
      });
  };

  // Watch the value of the "password" field
  const password = watch("password");

  return (
    <DashboardLayout>
      <div className="w-100 mb-3 rounded p-2" style={{ background: "#f8f9fa" }}>
        <h5 className="text-title mt-3">Change Your Password</h5>
        <form
          className="container row form-row px-5 mx-auto my-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-md-12">
            <div className="form-group mb-3 card-label">
              <label>Old Password</label>
              <input
                type="password"
                {...register("oldPassword", { required: true })}
                placeholder="Old Password"
                className="form-control"
              />
              {errors.oldPassword?.type === "required" && (
                <span className="text-danger text-sm">
                  Old password is required
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-3 card-label">
              <label>New Password</label>
              <input
                type="password"
                {...register("password", { required: true })}
                placeholder="New Password"
                className="form-control"
              />
              {errors.password?.type === "required" && (
                <span className="text-danger text-sm">
                  Password is required
                </span>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>Confirm Password</label>
              <input
                type="password"
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
                placeholder="Confirm Password"
                className="form-control"
              />

              {errors.confirmPassword?.type === "required" && (
                <span className="text-danger text-sm">
                  Confirm password is required
                </span>
              )}
              {errors.confirmPassword?.type === "validate" && (
                <span className="text-danger text-sm">
                  {errors.confirmPassword.message}
                </span>
              )}
            </div>
          </div>
          <div className="mt-5 text-center">
            <Button htmlType="submit" type="primary" size="large">
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default ChangePassword;
