import { Autocomplete, GoogleMap, Marker } from "@react-google-maps/api";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { TbCurrentLocation } from "react-icons/tb";
const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const GoogleMapComponent = ({
  location,
  setLocation,
  showFixLocation = true,
}) => {
  const [userLocation, setUserLocation] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    if (showFixLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    }
  }, []);

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult !== null) {
      const place = searchResult.get("place");

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setLocation({ lat, lng, placeId: place.place_id });
      setZoom(16);
    }
  };

  const handleFixLocation = () => {
    if (userLocation) {
      setLocation(userLocation);
      setZoom(16);
    }
  };

  return (
    <div className="relative">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location || center}
        zoom={zoom}
        onClick={(e) => {
          if (showFixLocation) {
            setLocation({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
              placeId: e.placeId,
            });
          }
        }}
      >
        {location && location?.lat && location?.lng && (
          <Marker
            position={{
              lat: location.lat,
              lng: location.lng,
            }}
          />
        )}
        <Autocomplete
          className="w-[25rem] absolute top-2 left-[12rem]"
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
        >
          <input
            className="w-full border rounded p-2 hover:border-primary shadow-md focus-within::border-primary text-sm"
            type="text"
            allowClear
            placeholder="Search for a location"
          />
        </Autocomplete>
        {showFixLocation && (
          <div className="absolute bottom-6 right-[4rem]">
            <Button
              shape="circle"
              className="p-4 relative shadow"
              onClick={handleFixLocation}
              title="Current Location"
            >
              <TbCurrentLocation className="w-6 h-6 text-primary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            </Button>
          </div>
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapComponent;
