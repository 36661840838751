import { message } from "antd";
import React, { useEffect } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import { useAddFavouriteMutation } from "../../../redux/api/favouriteApi";
import "./BookDoctor.css";

import doctor2 from "../../../images/doc/doctor2.png";
import icon from "../../../images/img/tilde.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Heading from "../../UI/component/Heading";

const DoctorCard = ({ doctor }) => {
  const calculateExperience = (year) => {
    const years = new Date().getFullYear() - year;
    return years;
  };
  return (
    <div className="card book-doctor  bg-white shadow-md rounded-lg p-4  md:min-h-[17rem] flex flex-col justify-between md:flex-row">
      {/* Doctor Image Section */}
      <div className="flex-none sm:w-full md:w-1/3 flex justify-center items-center mb-4 md:mb-0 !w-full">
        <Link to={`/doctors/profile/${doctor?.id}`}>
          <img
            src={doctor?.img || doctor2}
            alt="doctor"
            className="rounded-full h-24 w-24 object-cover"
          />
        </Link>
      </div>

      {/* Doctor Info Section */}
      <div className="flex-grow md:w-2/3 md:pl-4 flex flex-col  text-center !w-full !h-full">
        {doctor?.experienceStart && (
          <span className="text-sm text-gray-500 mb-2">
            {calculateExperience(doctor?.experienceStart)} Years Experience
          </span>
        )}
        <div className="text-base font-medium text-gray-800 h-5">
          {doctor?.designation}
        </div>

        <StarRatings
          rating={doctor?.rating || 5}
          starRatedColor="#f4c150"
          numberOfStars={5}
          name="rating"
          className="star"
          starDimension="20px"
          starSpacing="5px"
        />

        <div className="text-lg font-bold text-gray-900 capitalize ">
          {doctor?.firstName + " " + doctor?.lastName}
        </div>
        <div className="flex justify-center">
          <Link
            to={`/booking/${doctor?.id}`}
            className="!text-primary hover:text-primary/80 flex items-center"
          >
            Book Appointment
            <FaArrowCircleRight className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

const BookDoctor = () => {
  const { data, isError, isLoading } = useGetDoctorsQuery({
    limit: 10,
    active: true,
    verified: true,
  });
  const doctors = data?.doctors;
  const [
    addFavourite,
    { isSuccess, isLoading: FIsLoading, isError: fIsError, error },
  ] = useAddFavouriteMutation();

  const handleAddFavourite = (id) => {
    addFavourite({ doctorId: id });
  };

  useEffect(() => {
    if (!FIsLoading && fIsError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Favourite Added");
    }
  }, [isSuccess, fIsError, FIsLoading, error?.data?.message]);

  // what to render
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && doctors?.length === 0) return null;
  if (!isLoading && !isError && doctors?.length > 0)
    content = (
      <>
        {doctors?.map((item) => (
          <SwiperSlide key={item.id}>
            <DoctorCard doctor={item} />
          </SwiperSlide>
        ))}
      </>
    );

  return (
    <section className="book-doctor">
      <div className="container">
        <Heading name="Book Our Doctor" iconUrl={icon} />
        <div className="mt-5">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
            spaceBetween={20} // Adjust space between slides
            slidesPerView={3} // Number of slides visible
            navigation
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            centeredSlides={true}
            pagination={{ clickable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {content}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BookDoctor;
